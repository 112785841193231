import React, { Component, useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Tabs, Tab, TabList, TabPanel } from 'react-web-tabs';
import axios from 'axios';
import { Label, TextField, SelectField, Row, Column, LeftDiv, RightDiv,CenterDiv, MandatoryLabelStream, MandatoryLabelBroadcaster, Button, ButtonSmall,DelteButtonSmall } from '../utils/Styles';
import * as Constants from '../utils/Constants';
import SelectOptions from '../components/SelectOptions';
import swal from "sweetalert";
import ls from 'local-storage';
import { List, SelectionControl, Slider } from 'react-md';
import styled from 'styled-components';
import { ReactComponent as CopySVG } from '../images/copy.svg';

const HR = styled.hr`
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
`;

const ColumnFog = styled.div`
  width: 100%;
  float:left;
  padding: 10px 2%
`;

const ResponsiveHR = styled(HR)`
    display: none;
    @media screen and (max-width: 1100px) {
        display: block;
        margin-top: 25px;
    }
`;

const ResponsiveRow = styled(Row)`
    display: flex;
    @media screen and (max-width: 1100px) {
        flex-direction: column;
    }
    & > ${Column} {
        @media screen and (max-width: 1100px) {
            width: 100%;
            max-width: 600px;
        }
    }
`;

const EditWEBRTCButton = styled(Button)`
  opacity: 0.4;
  pointer-events: none;
  :hover {
    cursor: pointer;
    opacity: 0.4;
  }
`;

const ResponsiveTabList = styled.div`  
  @media screen and (max-width: 850px) {
    & .rwt__tab {
      padding: 11.5px;
    }
  }
  @media screen and (max-width: 450px) {
    & .rwt__tab {
      padding: 9.5px;
    }
  }
  @media screen and (max-width: 395px) {
    & .rwt__tab {
      padding: 7px;
    }
  }
  @media screen and (max-width: 380px) {
    & .rwt__tab {
      padding: 7px 4px;
    }
  }
`;

const LabelDestination = styled.label`
  display:inline-block;
  margin-bottom:5px;
  @media screen and (max-width:400px){
    font-size:8px !important;
  }
`;
const LabelDestination1 = styled.label`
  display:inline-block;
  margin-bottom:5px;
  user-select : text;
  @media screen and (max-width: 400px) {
    font-size:9px !important;
}
`;

const IntervalDiv=styled.div`
    @media screen and (max-width: 400px) {
        margin-top:8px;
    }
`;

const SliderDiv = styled.div`
    @media screen and (max-width: 400px) {
        margin-top:8px;
    }
`;

const CopyButtonDestination = styled(CopySVG)`
  height: 20px;
  width: 18px;
  object-fit: contain;
  cursor: pointer;
  opacity: 0.5;
  padding: 2px !important;
  margin: 1px 0px 5px 0px !important;
  font-size: 0.7em !important;
  width: 40px;
  @media screen and (max-width: 550px) {
    height: 18px;
    width: 18px;
    margin: -1px 2px 6px 5px !important;
  }
`;

const LeftDivToggle=styled.div`
  float: left;
  padding-right: 20px;
  @media screen and (max-width: 400px) {
    margin-top: 13px !important;
  }
`;

const BroadcastingTab = (props) => {
    const [inputs, setInputs] = useState(props.source === "MLIT" ? { ...Constants.BROADCASTER_DEFAULT_VALUES, rtsp_enabled: true } : Constants.BROADCASTER_DEFAULT_VALUES);
    const [isMobileBrowser, setIsMobileBrowser] = useState(false);
    const [hlsState, setHlsState] = useState({});
    const [webrtcState, setWebrtcState] = useState({});
    const [rtspState, setRtspState] = useState({});
    const [rtmpReadOnly, setRtmpReadOnly] = useState(true);
    const [rtpReadOnly, setRtpReadOnly] = useState(true);
    const [fogReadOnly, setFogReadOnly] = useState(true);
    const [kinesisReadOnly, setKinesisReadOnly] = useState(true);
    const [hlsReadOnly, setHlsReadOnly] = useState(true);
    const [webRTCReadOnly, setWebRTCReadOnly] = useState(true);
    const [rtspReadOnly, setRtspReadOnly] = useState(true);
    const [frameEmmiterReadOnly, setFrameEmmiterReadOnly] = useState(true);
    const [selectedTab, setSelectedTab] = useState("fog");
    const [copiedText, setCopiedText] = useState(false);

    const source = props.source;
    var isViewPage = props.readOnly;
    var initialValues = props.data;
    var rtspServ = props.rtspEnable;
    var stackName = props.stackName;
    var senderPort = props?.data?.webrtc_broadcaster?.sender_port
    var stackStatus = props.stackStatus
    var serviceName = Object.keys(props.data);
    var services = props.rtspEnable?.services;
    const location = useLocation();
    const history = useHistory();
    const disabledTabState = ((source === "FTP" && (isViewPage || !isViewPage)) || (source === "SAFIE" && (isViewPage || !isViewPage)));

    // Update parent with inputs value 
    props.parentCallback(inputs);
    useEffect(()=>{
        var isMobileBrowser = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        if(isMobileBrowser) {
        setIsMobileBrowser(isMobileBrowser);
        } else {
        setIsMobileBrowser(isMobileBrowser);
        }
    },[]);

    useEffect(() => {
        if (isViewPage) {
            const params = new URLSearchParams(location.search);
            const newSelectedTab = params.get('innerTab');
            if (newSelectedTab) {
                setSelectedTab(newSelectedTab);
            }
        }
    }, [location.search]);

    const onTabChange = useCallback((newTab) => {
        if (isViewPage) {
            setSelectedTab(newTab);
            history.push(`./view-stream?stream=${stackName}&selectedTab=broadcastingTab&innerTab=${newTab}`);
        }
    }, [location.search]);
    
    useEffect(() => {
        if (isViewPage) {
            let mergeData = Constants.BROADCASTER_DEFAULT_VALUES;
            
            // if ((services && services["recorder1"]["frame_emitter_enabled"] === true) && (services && services["recorder1"]["legacy_mode"] === false)) {
            //     initialValues.legacy_mode = services["recorder1"]["legacy_mode"];
            //     mergeData = { ...mergeData, ...initialValues };
            // }

            // if (initialValues.fog_broadcaster1 && initialValues.fog_broadcaster1.legacy_mode === true) {
            //     initialValues.fog_broadcaster1.legacy_mode = initialValues.fog_broadcaster1.legacy_mode
            //     mergeData = { ...mergeData, ...initialValues };
            // }

            if (initialValues.rtmp_broadcaster1) {
                initialValues.rtmp_broadcaster1.rtmp_enabled = initialValues.rtmp_broadcaster1.enabled
                mergeData = { ...mergeData, ...initialValues.rtmp_broadcaster1 };
            }

            if (initialValues.rtp_broadcaster1) {
                initialValues.rtp_broadcaster1.rtp_enabled = initialValues.rtp_broadcaster1.enabled
                mergeData = { ...mergeData, ...initialValues.rtp_broadcaster1 };
            }

            if (initialValues.fog_broadcaster1) {
                initialValues.fog_broadcaster1.fog_enabled = initialValues.fog_broadcaster1.enabled
                mergeData = { ...mergeData, ...initialValues.fog_broadcaster1 };
            }

            if (initialValues.kinesis_streamer1) {
                initialValues.kinesis_streamer1.kinesis_enabled = initialValues.kinesis_streamer1.enabled
                mergeData = { ...mergeData, ...initialValues.kinesis_streamer1 };
            }

            if (services && services["recorder1"]) {
                initialValues.hls_enabled = services["recorder1"]["hls_enabled"];
                initialValues.playlist_length = services["recorder1"]["playlist_length"];
                initialValues.target_duration = services["recorder1"]["target_duration"];
                mergeData = { ...mergeData, ...initialValues };
            }

            if (rtspServ) {
                initialValues.webrtc_enabled = rtspServ.webrtc_enabled;
                mergeData = { ...mergeData, ...initialValues };
            }

            if (rtspServ.rtsp_enabled) {
                initialValues.rtsp_enabled = rtspServ.rtsp_enabled;
                mergeData = { ...mergeData, ...initialValues };
            }
            
            if (services && services["recorder1"]) {
                initialValues.frame_emitter_enabled = services["recorder1"]["frame_emitter_enabled"];
                initialValues.frame_emitter_stream_id = services["recorder1"]["stream_id"];
                initialValues.frame_emitter_conn_ep = services["recorder1"]["frame_emitter_conn_ep"];
                mergeData = { ...mergeData, ...initialValues };
            }
            setInputs(mergeData);
        }
    }, [isViewPage, initialValues, rtspServ, services]);

    function validateEndpoints(){
        let destArr = inputs["destinations"];
        for(var i=0; i<destArr.length;i++){
            if(destArr[i].includes("://*")){
                swal({text: `\n\nEndpoint type 'connect' can not have *`});
                return false;
            }
        }
        return true;
    }

    const handleOnChange = (event) => {
        if(event.target.name === "endpoint_type" && event.target.value === "connect"){
            if(!validateEndpoints()){
                return;
            }
        }
        event.persist();
        if(event.target.name === "rtmp_url"){
            let rtmpurl = event.target.value
            if (rtmpurl.length > 255){
                swal({text: "\n\nThe text entered exceeds the maximum length"});
                return;
            }
        }

        if(event.target.name === "protocol"){
            if(event.target.value === "fog"){
                setInputs(Constants.FOG_BROADCASTER_DEFAULT_VALUES);
            } else if(event.target.value === "rtp"){
                setInputs(Constants.BROADCASTING_RTP_DEFAULT_VALUES);
            } else {
                let data = {...inputs, [event.target.name]: event.target.value};
                setInputs(data);
            }
        } else {
            if( event.target.name === "stream_key" ||
                event.target.name === "kinesis_streamer_secret_access_key" ||
                event.target.name === "kinesis_streamer_access_key_id"){
                event.target.value = event.target.value.replace(" ", "")
            } else if (event.target.name === 'stream_id') {
                if (event.target.value.charAt(0) === '_') {
                  return event.target.value.replace(/[?\\$\s]/g, '');
                } else {
                  event.target.value = event.target.value.replace(/[?\\$\s]/g, '');
                }
              }
            let data = {...inputs, [event.target.name]: event.target.value};
            setInputs(data);
        }
    }

    const handleIntegerOnChange = (event) => {
        event.persist();
        let regEx = /^[1-9][0-9]*$/
        if(regEx.exec(event.target.value)){
            setInputs({...inputs, [event.target.name]: event.target.value});
        } else {
            if(event.target.value == "" || event.target.value == "0"){
                setInputs({...inputs, [event.target.name]: event.target.value});
            }
        }
    }

    const handleFloatOnChange = (event) => {
        event.persist();
        let regEx = /^(?:[1-9]\d*|0)?(?:\.\d+)?$/
        if(regEx.exec(event.target.value)){
            setInputs({...inputs, [event.target.name]: event.target.value});
        } else {
            let dotIndex = event.target.value.indexOf(".");
            if(event.target.value === ""){
                setInputs({...inputs, [event.target.name]: event.target.value});
            } else if(dotIndex > 0 && dotIndex ===  event.target.value.length-1){
                setInputs({...inputs, [event.target.name]: event.target.value});
            } else if(dotIndex === 0 && event.target.value.length === 1){
                setInputs({...inputs, [event.target.name]: event.target.value});
            }
        }
    }

    const handlePortOnChange = (event) => {
        event.persist();
        let regEx = /^[1-9][0-9]*$/
        if(regEx.exec(event.target.value)){
            setInputs({...inputs, [event.target.name]: event.target.value});
        } else {
            if(event.target.value == "" || event.target.value == "0"){
                setInputs({...inputs, [event.target.name]: ""});
            }
        }
    }

    function deleteBroadcasterRequest (broadcasterType) {
        axios.delete(Constants.EDGE_API_ENDPOINT + '/stacks/' + stackName + "/" + broadcasterType)
            .then(res => {
                // setInputs(undefined);
                swal("Broadcaster reset successfully.", { icon: "success"});
                setTimeout(function(){ window.location.reload() }, 2000);
            })
            .catch(error => {
                if(error.response){
                    var errorObj = error.response.data;
                    swal("Error Code: " + errorObj.error.code +"\nError Message: " + errorObj.error.message, { icon: "error"});
                }else{
                    swal({text: "Unable to connect to the edge-api service" , icon: "error"});
                }
            });
    }

    const resetBroadcaster = (event, broadcasterType) => {
        event.persist();
        if(isViewPage && initialValues!=undefined){
            swal({
                text: "\n\nAre you sure you want to reset this broadcaster?",
                buttons: ['No', 'Yes'],
                dangerMode: true,
            })
            .then((willDelete) => {
                if (willDelete) {
                    deleteBroadcasterRequest (broadcasterType)
                }
            });

        } else {
            setInputs(undefined);
        }
    }

    const resetFrameEmitter = (event) => {
        event.persist();
        let jsonData = {
            type: "recorder",
            frame_emitter_enabled: false,
            stream_id: "auto",
            frame_emitter_conn_ep: []
        }
        if (isViewPage && initialValues != undefined) {
            swal({
                text: "\n\nAre you sure you want to reset this broadcaster?",
                buttons: ['No', 'Yes'],
                dangerMode: true,
            }).then((willDelete) => {
                    if (willDelete) {
                        axios.put(Constants.EDGE_API_ENDPOINT + '/stacks/' + stackName + "/recorder1", jsonData)
                            .then(res => {
                                swal("Broadcaster reset successfully.", { icon: "success" });
                                setTimeout(function () { window.location.reload() }, 2000);
                            })
                            .catch(error => {
                                if (error.response) {
                                    var errorObj = error.response.data;
                                    swal("Error Code: " + errorObj.error.code + "\nError Message: " + errorObj.error.message, { icon: "error" });
                                } else {
                                    swal({ text: "Unable to connect to the edge-api service", icon: "error" });
                                }
                            });
                    }
                });

        } else {
            setInputs(undefined);
        }
    }

    const editRtmpBroadcaster = () => {
        setRtmpReadOnly(false)
    }

    const editRtpBroadcaster = () => {
        setRtpReadOnly(false)
    }

    const editFogBroadcaster = () => {
        setFogReadOnly(false)
    }

    const editKinesisBroadcaster = () => {
        setKinesisReadOnly(false)
    }

    const editHlsBroadcaster = () => {
        setHlsReadOnly(false)
    }

    const editRtspBroadcaster = () => {
        setRtspReadOnly(false)
    }

    const editFrameEmmiter = () => {
        setFrameEmmiterReadOnly(false)
    }

    const cancelBroadcaster = (event) => {
        window.location.reload()
    }

   const addDestination = (event) => {
        if(inputs["destination_text"] === undefined){
            return;
        }

        if(inputs["endpoint_type"] === "connect" && inputs["destination_text"].includes("://*")){
            swal({text: `Endpoint type 'connect' can not have *`, icon: "error" });
            return;
        }

        let destinationsList = inputs["destinations"];
        if(destinationsList === undefined){
            destinationsList = [];
        }

        let destinationText = inputs["destination_text"].trim();
        if(destinationsList.indexOf(destinationText) !== -1){
            swal({text: "Destination already exists", icon: "error" });
            return;
        }

        if(destinationText.startsWith("tcp://") ||
           destinationText.startsWith("ipc://") ||
           destinationText.startsWith("pgm://") ||
           destinationText.startsWith("epgm://"))
       {
            destinationsList.push(destinationText);
            setInputs({...inputs, ["destinations"]: destinationsList});
            setInputs({...inputs, ["destination_text"]: ""});
       } else {
           swal({text: "Destination must be starts with tcp://, ipc://, pgm://, epgm://", icon: "error" });
       }
    }

    const addFrameEmitterDestination = (event) => {
        if (inputs["fm_destination_text"] === undefined) {
            return;
        }

        if (inputs["fm_destination_text"].includes("://*")) {
            swal({ text: `Endpoint type 'connect' can not have *`, icon: "error" });
            return;
        }

        let destinationsList = inputs["frame_emitter_conn_ep"];
        if (destinationsList === undefined) {
            destinationsList = [];
        }

        let destinationText = inputs["fm_destination_text"].trim();
        if (destinationsList.indexOf(destinationText) !== -1) {
            swal({ text: "Destination already exists", icon: "error" });
            return;
        }

        if (destinationText.startsWith("tcp://") ||
            destinationText.startsWith("ipc://") ||
            destinationText.startsWith("pgm://") ||
            destinationText.startsWith("epgm://")) {
            destinationsList.push(destinationText);
            setInputs({ ...inputs, ["frame_emitter_conn_ep"]: destinationsList });
            setInputs({ ...inputs, ["fm_destination_text"]: "" });
        } else {
            swal({ text: "Destination must be starts with tcp://, ipc://, pgm://, epgm://", icon: "error" });
        }
    }

    const clickToCopy = (elementId) => {
        let copyElement = document.getElementById(elementId);
        const textarea = document.createElement('textarea');
        textarea.id = 'copyTextArea';
        textarea.value = copyElement.innerText;
        textarea.setAttribute('readonly', '');
        textarea.style.position = 'absolute';
        textarea.style.width = '0px';
        textarea.style.height = '0px';
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        copyElement.blur();
        document.getElementById('copyTextArea').remove();
      };

    const deleteDestination = (event, eventType) => {
        if(eventType === 'fogDestinations'){
            let index = event.target.getAttribute("data");
            let destArr = inputs["destinations"];
            let data = { ...inputs };
            data.destination_text = destArr.splice(index, 1)[0];
            data.destinations = destArr
            setInputs(data);
        }

        if(eventType === 'frameEmitterDestinations'){
            let index = event.target.getAttribute("data");
            let destArr = inputs["frame_emitter_conn_ep"];
            let data = { ...inputs };
            data.destination_text = destArr.splice(index, 1)[0];
            data.frame_emitter_conn_ep = destArr
            setInputs(data);
        }
    }

    const handleToggleChange = (value, event) => {
        let eventId = event.target.id;
        if (eventId === 'rtmp_enabled' ) {
            if (!rtmpReadOnly || !isViewPage) {
                setInputs({ ...inputs, [event.target.id]: value });
            }
        }
        if (eventId === 'rtp_enabled') {
            if (!rtpReadOnly || !isViewPage) {
                setInputs({ ...inputs, [event.target.id]: value });
            }
        }
        if (eventId === 'fog_enabled' || eventId === 'compression' || eventId === 'frame_rate_logging') {
            if (!fogReadOnly || !isViewPage) {
                setInputs({ ...inputs, [event.target.id]: value });
                if (event.target.id == "network_mode") {
                    if (value == true) {
                        setInputs({ ...inputs, [event.target.id]: "host" });
                    } else {
                        setInputs({ ...inputs, [event.target.id]: "" });
                    }
                }
            }
        }
        if (eventId === 'kinesis_enabled') {
            if (!kinesisReadOnly || !isViewPage) {
                setInputs({ ...inputs, [event.target.id]: value });
            }
        }
        if (eventId === 'hls_enabled') {
            if (!hlsReadOnly || !isViewPage) {
                setInputs({ ...inputs, [event.target.id]: value });
            }
        }
        if (eventId === 'webrtc_enabled') {
            if (!webRTCReadOnly || !isViewPage) {
                setInputs({ ...inputs, [event.target.id]: value });
            }
        }

        if (eventId === 'rtsp_enabled') {
            if (!rtspReadOnly || !isViewPage) {
                setInputs({ ...inputs, [event.target.id]: value });
            }
        }

        // if (eventId === 'frame_emitter_enabled') {
        //     if (!frameEmmiterReadOnly || !isViewPage) {
        //         setInputs({ ...inputs, [event.target.id]: value });
        //     }
        // }

        // if (eventId === 'legacy_mode') {
        //         setInputs({ ...inputs, [event.target.id]: value });
        // }
    };

    const handleRadioChange = (event) => {
        event.persist();
        let data = { ...inputs };
        data[event.target.name] = event.target.value;
        setInputs(data);
      };

    const handleSliderChange = (value, event) => {
        setInputs({...inputs, ["compression_quality"]: value});
    };

    const handleSelectChange = (event) => {
        setInputs({ ...inputs, [event.target.name]: event.target.checked });
    };


    function isEmpty(val){
        if(val == undefined || val.trim() == "") {
            return true;
        }else{
            return false;
        }
    }
    function validIP(ip) {
        const re = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
        return re.test(ip);
    }

    const handleSuccessReq = (res) => {
        setTimeout(function () { window.location.reload() }, 500);
    }
    
    const handleErrorReq =(error)=>{
        if (error.response) {
            var errorObj = error.response.data;
            swal("Error Code: " + errorObj.error.code + "\nError Message: " + errorObj.error.message, { icon: "error" });
        } else {
            swal({ text: "Unable to connect to the edge-api service", icon: "error" });
        }
    }

    const saveBroadcaster = (event, broadcasterType) => {
        var broadcasterJson = Object.assign({}, inputs);
        broadcasterJson["enabled"] = stackStatus

        broadcasterJson["type"] = broadcasterType;
        let unifiedJson = {};
        if (broadcasterType === "fog_broadcaster1") {
            unifiedJson = {
                type: "fog_broadcaster",
                // legacy_mode: broadcasterJson.legacy_mode,
                enabled: broadcasterJson.fog_enabled,
                endpoint_type: broadcasterJson.endpoint_type,
                destinations: broadcasterJson.destinations,
                stream_id: broadcasterJson.stream_id,
                compression: broadcasterJson.compression,
                compression_quality: broadcasterJson.compression_quality,
                frame_rate_logging: broadcasterJson.frame_rate_logging,
                frame_rate_logging_interval: broadcasterJson.frame_rate_logging_interval,
                emit_interval: broadcasterJson.emit_interval,
                network_access: broadcasterJson.network_access,
                watchdog_timeout: broadcasterJson.watchdog_timeout
            }
        } else if (broadcasterType === "hls_broadcaster1") {
            unifiedJson = {
                type: "recorder",
                hls_enabled: broadcasterJson.hls_enabled,
                target_duration: broadcasterJson.target_duration,
                playlist_length: broadcasterJson.playlist_length,
            }
        } else if (broadcasterType === "rtmp_broadcaster1") {
            unifiedJson = {
                type: "rtmp_broadcaster",
                rtmp_url: broadcasterJson.rtmp_url,
                enabled: broadcasterJson.rtmp_enabled,
                stream_key: broadcasterJson.stream_key,
            };
        } else if (broadcasterType === "rtp_broadcaster1") {
            unifiedJson = {
                type: "rtp_broadcaster",
                enabled: broadcasterJson.rtp_enabled,
                rtp_audio_udp_port: broadcasterJson.rtp_audio_udp_port,
                rtp_server_ip: broadcasterJson.rtp_server_ip,
                rtp_video_udp_port: broadcasterJson.rtp_video_udp_port
            };
        }  else if (broadcasterType === 'kinesis_streamer1') {
            unifiedJson = {
                type: "kinesis_streamer",
                enabled: broadcasterJson.kinesis_enabled,
                kinesis_streamer_stream_name: broadcasterJson.kinesis_streamer_stream_name,
                kinesis_streamer_region_name: broadcasterJson.kinesis_streamer_region_name,
                kinesis_streamer_access_key_id: broadcasterJson.kinesis_streamer_access_key_id,
                kinesis_streamer_secret_access_key: broadcasterJson.kinesis_streamer_secret_access_key,
            }
        } else if (broadcasterType === 'rtsp_enabled') {
            unifiedJson = {
                "namespace": "default",
                rtsp_enabled: broadcasterJson.rtsp_enabled,
            }
        } 
        // else if (broadcasterType === 'frame_emmiter') {
        //     unifiedJson = {
        //         type: "recorder",
        //         legacy_mode : broadcasterJson.legacy_mode,
        //         frame_emitter_enabled: broadcasterJson.frame_emitter_enabled,
        //         stream_id: broadcasterJson.frame_emitter_stream_id,
        //         frame_emitter_conn_ep: broadcasterJson.frame_emitter_conn_ep
        //     }
        // }

        if (broadcasterType === "fog_broadcaster1") {
            if ( unifiedJson.enabled === true) {
                if (unifiedJson["destinations"].length === 0) {
                    swal({ text: "Please add destination", icon: "error" })
                    return;
                }
                if (isEmpty(unifiedJson["stream_id"])) {
                    swal({ text: "Please enter stream id", icon: "error" });
                    return;
                }
                if (unifiedJson["frame_rate_logging"] === true) {
                    if (isEmpty(unifiedJson["frame_rate_logging_interval"])) {
                        swal({ text: "Please enter frame rate logging interval", icon: "error" });
                        return;
                    }
                    if (parseFloat(unifiedJson["frame_rate_logging_interval"]) === 0.0) {
                        swal({ text: "frame rate logging interval should be > 0", icon: "error" });
                        return;
                    }
                }
                else if (unifiedJson["frame_rate_logging"] === false) {
                    if (parseFloat(unifiedJson["frame_rate_logging_interval"]) === 0.0) {
                        unifiedJson["frame_rate_logging_interval"] = ""
                    }
    
                }
                if (isEmpty(unifiedJson["emit_interval"])) {
                    swal({ text: "Please enter emit interval", icon: "error" });
                    return;
                }
                if (isEmpty(unifiedJson["watchdog_timeout"])) {
                    swal({ text: "Please enter watchdog timeout", icon: "error" });
                    return;
                }
                if (parseInt(unifiedJson["watchdog_timeout"]) < 0) {
                    swal({ text: "Watchdog timeout should be >= 0", icon: "error" });
                    return;
                }
                if (unifiedJson.stream_id === "auto") {
                    unifiedJson["stream_id"] = stackName;
                }
                delete unifiedJson["destination_text"];
            }
        } 
        // else if (broadcasterType === 'frame_emmiter') {
        //     if (broadcasterJson["legacy_mode"] === false && unifiedJson["frame_emitter_enabled"] === true) {
        //         if (isEmpty(unifiedJson["stream_id"])) {
        //             swal({ text: "Please enter stream id", icon: "error" });
        //             return;
        //         }
        //         else if (unifiedJson["frame_emitter_conn_ep"]?.length === 0) {
        //             swal({ text: "Please enter destination", icon: "error" });
        //             return;
        //         }
        //     }
        //     if (unifiedJson["stream_id"] === "auto") {
        //         unifiedJson["stream_id"] = stackName;
        //     }
        // } 
        else if (broadcasterType === "rtmp_broadcaster1") {
            if (unifiedJson.enabled === true) {
                var rtmpUrlFormat = /^(rtmp?):?\/\//
                if (!rtmpUrlFormat.test(unifiedJson?.rtmp_url)) {
                    if (isEmpty(unifiedJson?.rtmp_url)) {
                        swal({ text: "Please enter stream url", icon: "error" })
                    } else {
                        swal({ text: "Invalid stream url", icon: "error" })
                    }
                    return;
                }
                if (isEmpty(unifiedJson["stream_key"])) {
                    unifiedJson["stream_key"] = "";
                }
            }
        } else if (broadcasterType === "rtp_broadcaster1") {
            if (unifiedJson.enabled === true) {
                if (isEmpty(unifiedJson["rtp_server_ip"])) {
                    swal({ text: "Please enter server ip", icon: "error" });
                    return;
                } else if (!validIP(unifiedJson["rtp_server_ip"])) {
                    swal({ text: "Please enter valid server ip", icon: "error" });
                    return;
                }
                if (isEmpty(unifiedJson["rtp_video_udp_port"])) {
                    swal({ text: "Please enter video port", icon: "error" });
                    return;
                }
            }
        } else if (broadcasterType === "kinesis_streamer1") {
            if (unifiedJson.enabled === true) {
                if (isEmpty(unifiedJson["kinesis_streamer_stream_name"])) {
                    swal({ text: "Please enter stream name", icon: "error" });
                    return;
                }
                if (isEmpty(unifiedJson["kinesis_streamer_access_key_id"])) {
                    swal({ text: "Please enter access key", icon: "error" });
                    return;
                }
                if (isEmpty(unifiedJson["kinesis_streamer_secret_access_key"])) {
                    swal({ text: "Please enter secret key", icon: "error" });
                    return;
                }
                if (unifiedJson["kinesis_streamer_region_name"] === undefined || unifiedJson["kinesis_streamer_region_name"] === null) {
                    unifiedJson["kinesis_streamer_region_name"] = "ap-northeast-1";
                }
            }
        }

        broadcasterJson[broadcasterType] = Object.assign({}, unifiedJson);

        ['rtmp_enabled', 'rtmp_url', 'stream_key', 'rtp_enabled', 'rtp_audio_udp_port', 'rtp_server_ip', 'rtp_video_udp_port',
            'fog_enabled', 'endpoint_type', 'destinations', 'stream_id', 'compression', 'compression_quality', 'frame_rate_logging',
            'frame_rate_logging_interval', 'emit_interval', 'network_access', 'watchdog_timeout', 'kinesis_enabled', 'kinesis_streamer_stream_name',
            'kinesis_streamer_region_name', 'kinesis_streamer_access_key_id', 'kinesis_streamer_secret_access_key', 'hls_enabled',
            'target_duration', 'playlist_length', 'webrtc_enabled', 'rtsp_enabled', 'frame_emitter_enabled', 'frame_emitter_conn_ep'].forEach(prop => delete broadcasterJson[prop]);

        delete broadcasterJson["protocol"];

        const apiEndpoint = Constants.EDGE_API_ENDPOINT + '/stacks/' + stackName + "/" + broadcasterType;
        if (broadcasterType === 'rtmp_broadcaster1') {
            if (serviceName.includes("rtmp_broadcaster1")) {
                axios.patch(apiEndpoint, unifiedJson)
                    .then(handleSuccessReq)
                    .catch(handleErrorReq)
            } else {
                if (unifiedJson.enabled === true){
                    axios.put(apiEndpoint, unifiedJson)
                        .then(handleSuccessReq)
                        .catch(handleErrorReq)
                } else {
                    handleSuccessReq();
                }
            }
        } else if (broadcasterType === 'rtp_broadcaster1') {
            if (serviceName.includes("rtp_broadcaster1")) {
                axios.patch(apiEndpoint, unifiedJson)
                    .then(handleSuccessReq)
                    .catch(handleErrorReq)
            } else {
                if(unifiedJson.enabled === true){
                    axios.put(apiEndpoint, unifiedJson)
                        .then(handleSuccessReq)
                        .catch(handleErrorReq)
                } else {
                    handleSuccessReq();
                }
            }
        } else if (broadcasterType === 'fog_broadcaster1') {
            if (serviceName.includes("fog_broadcaster1")) {
                axios.patch(apiEndpoint, unifiedJson)
                    .then(handleSuccessReq)
                    .catch(handleErrorReq)
            } else {
                if(unifiedJson.enabled === true){
                    axios.put(apiEndpoint, unifiedJson)
                        .then(handleSuccessReq)
                        .catch(handleErrorReq)
                } else {
                    handleSuccessReq();
                }
            }
        } else if (broadcasterType === 'kinesis_streamer1') {
            if (serviceName.includes("kinesis_streamer1")) {
                axios.patch(apiEndpoint, unifiedJson)
                    .then(handleSuccessReq)
                    .catch(handleErrorReq)
            } else {
                if(unifiedJson.enabled === true){
                    axios.put(apiEndpoint, unifiedJson)
                        .then(handleSuccessReq)
                        .catch(handleErrorReq)
                } else {
                    handleSuccessReq();
                }
            }
        } else if (broadcasterType === 'hls_broadcaster1') {
            axios.put(Constants.EDGE_API_ENDPOINT + '/stacks/' + stackName + "/recorder1", unifiedJson)
                .then(handleSuccessReq)
                .catch(handleErrorReq)
        } else if (broadcasterType === 'rtsp_enabled') {
            axios.patch(Constants.EDGE_API_ENDPOINT + '/stacks/' + stackName, unifiedJson)
                .then(handleSuccessReq)
                .catch(handleErrorReq)
        } else if (broadcasterType === 'webrtc_enabled') {
            axios.patch(Constants.EDGE_API_ENDPOINT + '/stacks/' + stackName, unifiedJson)
                .then(handleSuccessReq)
                .catch(handleErrorReq)
        } else if (broadcasterType === 'frame_emmiter') {
            axios.put(Constants.EDGE_API_ENDPOINT + '/stacks/' + stackName + "/recorder1", unifiedJson)
                .then(handleSuccessReq)
                .catch(handleErrorReq)
        }
    }

    const handleHlsLabelOnChange=(value)=>{
        setHlsState({...hlsState ,["hls_url_type"]: value})
    }

    const handleWebrtcLabelOnChange = (value) => {
        setWebrtcState({...webrtcState, ["webrtc_url_type"]: value});
    }

    const handleRtspLabelOnChange = (value) => {
        setRtspState({...rtspState, ["rtsp_url_type"]: value});
    }

    const copiedTextTimeout = () => {
        setCopiedText(true);
        setTimeout(() => {
            setCopiedText(false);
        }, 1000)
    }

    const copyAddress = (event, eventType) => {
        if (eventType === 'copyHlsUrl') {
            let hlsAddress = document.getElementsByName("hlsAddress")[0]
            hlsAddress.select();
            document.execCommand("copy");
            hlsAddress.blur();
            copiedTextTimeout();
        }
        if (eventType === 'copyWebRTCUrl') {
            let webRTCAddressElement = document.getElementsByName("webRTCAddress")[0]
            webRTCAddressElement.select();
            document.execCommand("copy");
            webRTCAddressElement.blur();
            copiedTextTimeout();
        }
        if (eventType === 'copyRtspUrl') {
            let rtspAddressElement = document.getElementsByName("rtspAddress")[0]
            rtspAddressElement.select();
            document.execCommand("copy");
            rtspAddressElement.blur();
            copiedTextTimeout();
        }
    }

    useEffect(()=>{
        const mDnsName = ls.get("device_id") === undefined ? "" : ls.get("device_id") + ".local";
        const deviceIP = ls.get("device_ip") === undefined ? window.location.hostname : ls.get("device_ip");
        const rtspPort = rtspServ?.rtsp_port === undefined ? '""' : rtspServ?.rtsp_port;
        console.log(rtspServ,'rtspServ')
        
        var hlsService = {}
        let mDnsHlsUrl = "http://" + mDnsName + ":" + "1835" + "/live/" + `${stackName === undefined  ? '""' : stackName}`  + "/playlist.m3u8";
        let deviceIPHlsUrl = "http://" + deviceIP + ":" + "1835" + "/live/" + `${stackName === undefined  ? '""' : stackName}`  + "/playlist.m3u8";
        hlsService["peer_address_mdns1"] = mDnsHlsUrl;
        hlsService["peer_address_ip1"] = deviceIPHlsUrl;
        hlsService["hls_url_type"] = "mDNS";
        setHlsState(hlsService);

        var deviceIPRtspUrl;
        var mDnsRtspUrl;

        var webrtcService = {};
        let mDnsWebrtcUrl = "ws://" + mDnsName + ":" + Constants.EDGE_UI_PORT + "/websocket-" + (senderPort === undefined ? '""' : senderPort);
        let deviceIPWebrtcUrl = "ws://" + deviceIP + ":" + Constants.EDGE_UI_PORT + "/websocket-" + (senderPort === undefined ? '""' : senderPort);
        webrtcService["peer_address_mdns"] = mDnsWebrtcUrl;
        webrtcService["peer_address_ip"] = deviceIPWebrtcUrl;
        webrtcService["webrtc_url_type"] = "mDNS";
        setWebrtcState(webrtcService);

        var rtspService = {}
        mDnsRtspUrl = "rtsp://" + mDnsName + ":" + rtspPort + "/" + (stackName === undefined ? '""' : stackName);
        deviceIPRtspUrl = "rtsp://" + deviceIP + ":" + rtspPort + "/" + (stackName === undefined ? '""' : stackName);
        rtspService["peer_address_mdns"] = mDnsRtspUrl;
        rtspService["peer_address_ip"] = deviceIPRtspUrl;
        rtspService["rtsp_url_type"] = "mDNS";
        setRtspState(rtspService);
    },[rtspServ, senderPort])

    return (
        <TabPanel tabId="broadcastingTab">
            <Tabs defaultTab={selectedTab} onChange={onTabChange}>
                <TabList>
                    <ResponsiveTabList>
                        <Tab tabFor="fog" className={'rwt__tab'}>Fog</Tab>
                        <Tab tabFor="hls" className={'rwt__tab'} disabled={disabledTabState}>HLS</Tab>
                        <Tab tabFor="rtmp" className={'rwt__tab'} disabled={disabledTabState}>RTMP</Tab>
                        <Tab tabFor="rtp" className={'rwt__tab'} disabled={disabledTabState}>RTP</Tab>
                        <Tab tabFor="kinesis" className={'rwt__tab'} disabled={disabledTabState}>Kinesis</Tab>
                        <Tab tabFor="WebRTC" className={'rwt__tab'} disabled={disabledTabState}>WebRTC</Tab>
                        <Tab tabFor="rtsp" className={'rwt__tab'} disabled={disabledTabState}>RTSP</Tab>
                    </ResponsiveTabList>
                </TabList>

                <TabPanel tabId="fog">
                    {/* <div style={{ margin: '17px 0 0 30px', 'display':'flex' }}>
                        <input style={{ 'margin': '1px 6px 0 0px', width: '16px', height: '16px' }} type="checkbox" id="legacy_mode" name="legacy_mode" value={inputs?.legacy_mode} checked={inputs?.legacy_mode} disabled={(isViewPage && ((serviceName.includes('fog_broadcaster1') && inputs?.legacy_mode === true) || (rtspServ?.services?.recorder1?.frame_emitter_enabled === true && inputs?.legacy_mode === false)))} onChange={handleSelectChange} />
                        <Label style={{ 'marginTop': '-2px', 'color': `${(isViewPage && ((serviceName.includes('fog_broadcaster1') && inputs?.legacy_mode === true) || (rtspServ?.services?.recorder1?.frame_emitter_enabled === true && inputs?.legacy_mode === false))) ? 'rgb(135, 149, 139)' : 'black'}`}}>Legacy Mode</Label>
                    </div> */}

                {/* { inputs?.legacy_mode === true &&  */}
                    <Row>
                        <ResponsiveRow>
                            <ColumnFog>
                                <div style={{ 'margin': '8px 0 0 0.5px' }}>
                                    <SelectionControl style={{ height: 'auto' }} id="fog_enabled" type="switch" label={inputs?.fog_enabled ? "Enabled" : "Disabled"} name="fog_enabled" labelBefore="true" checked={inputs?.fog_enabled || false} disabled={isViewPage && serviceName.includes("fog_broadcaster1")} onChange={handleToggleChange} />
                                </div>
                                <Row style={{ 'marginTop': '15px' }}>
                                    <Row>
                                    <div style={{ 'marginLeft': '-2px' }}>
                                        <Row>
                                            <fieldset >
                                                <legend>Network Access</legend>
                                                <Row style={{ 'marginTop': '15px' }}>
                                                    <div style={{ 'display': 'flex', 'paddingBottom': '15px', 'flexDirection': isMobileBrowser ? 'column' : 'row' }}>
                                                        <div>
                                                            <input type="radio" id="network_access" name="network_access" value="deny_all" checked={inputs?.network_access === 'deny_all' || false} onChange={handleRadioChange} disabled={fogReadOnly && isViewPage} /> {Constants.PROJECT_NETWORK_ACCESS.deny_all}
                                                        </div>
                                                        <div style={{ 'marginLeft': isMobileBrowser ? '0' : '15px' }}>
                                                            <input type="radio" id="network_access" name="network_access" value="allow_inside_device" checked={inputs?.network_access === 'allow_inside_device' || false} onChange={handleRadioChange} disabled={fogReadOnly && isViewPage} /> {Constants.PROJECT_NETWORK_ACCESS.allow_inside_device}
                                                        </div>
                                                        <div style={{ 'marginLeft': isMobileBrowser ? '0' : '15px' }}>
                                                            <input type="radio" id="network_access" name="network_access" value="allow_all" checked={inputs?.network_access === 'allow_all' || false} onChange={handleRadioChange} disabled={fogReadOnly && isViewPage} /> {Constants.PROJECT_NETWORK_ACCESS.allow_all}
                                                        </div>
                                                    </div>
                                                </Row>
                                            </fieldset>
                                        </Row>
                                        <Row>
                                            <fieldset>
                                                <legend>Endpoint Type</legend>
                                                <Row style={{ 'marginTop': '15px' }}>
                                                    <div style={{ 'display': 'flex', 'paddingBottom': '15px', 'flexDirection': isMobileBrowser ? 'column' : 'row' }}>
                                                        <div>
                                                            <input type="radio" id="bind" name="endpoint_type" value="bind" onChange={handleOnChange} checked={inputs?.endpoint_type === "bind" || false} disabled={fogReadOnly && isViewPage}></input> Bind
                                                        </div>
                                                        <div style={{ 'marginLeft': isMobileBrowser ? '0' : '15px' }}>
                                                            <input type="radio" id="connect" name="endpoint_type" value="connect" onChange={handleOnChange} checked={inputs?.endpoint_type === "connect" || false} disabled={fogReadOnly && isViewPage}></input> Connect
                                                        </div>
                                                    </div>
                                                </Row>
                                            </fieldset>
                                        </Row>
                                    </div>
                                        <Row style={{ 'width': '100%' }}>
                                            <Label>Destinations</Label>
                                            {isMobileBrowser ?
                                                <div style={{ 'width': '100%', 'display': 'flex' }}>
                                                    <div style={{ 'width': '75%' }}>
                                                        <TextField name="destination_text" type="text" disabled={fogReadOnly && isViewPage} onChange={handleOnChange} value={inputs?.destination_text} />
                                                    </div>
                                                    <div style={{ 'marginLeft': 'auto' }}>
                                                        <Button primary style={{ "float": "right", 'margin': '0' }} onClick={addDestination} disabled={fogReadOnly && isViewPage}>Add</Button>
                                                    </div>
                                                </div> :
                                                <div style={{ 'width': '100%' }}>
                                                    <LeftDiv style={{ "width": "85%", padding: '0' }}>
                                                        <TextField name="destination_text" type="text" disabled={fogReadOnly && isViewPage} onChange={handleOnChange} value={inputs?.destination_text} />
                                                    </LeftDiv>
                                                    <RightDiv style={{ "width": "15%", "marginTop": "-1em" }}>
                                                        <Button primary style={{ 'float': 'right' }} onClick={addDestination} disabled={fogReadOnly && isViewPage}>Add</Button>
                                                    </RightDiv>
                                                </div>}
                                        </Row>
                                        <Row style={{ 'width': '100%', 'marginBottom': (inputs?.destinations === undefined || inputs?.destinations.length === 0) ? '10px' : '40px' }}>
                                            <List id="destinations" name="destinations" style={{ "marginTop": "10px", "marginBottom": "10px", 'width': '100%' }}>
                                                {inputs?.destinations !== undefined && Object.keys(inputs?.destinations).map((index) => {
                                                    let isActive = (parseInt(index) === inputs?.destinations.length - 1) ? true : false;
                                                    return (
                                                        <div>
                                                            {isMobileBrowser ?
                                                                <div style={{ 'width': '100%', 'display': 'flex', 'marginTop': '10px' }}>
                                                                    <div style={{ 'display': 'flex' }}>
                                                                        <LabelDestination1 id={inputs?.destinations[index] + 'destinationText'} style={{ 'fontSize': '13px', 'color': 'rgba(0,0,0,0.87)', 'whiteSpace': 'nowrap', 'overflow': 'hidden', 'textOverflow': 'ellipsis', 'maxWidth': '200px' }}>{inputs?.destinations[index]}</LabelDestination1>
                                                                        <div style={{ 'display': 'flex' }}>
                                                                            <LabelDestination1 style={{ 'fontSize': '13px' }}>{" (" + inputs["endpoint_type"] + ")"}</LabelDestination1>
                                                                            <CopyButtonDestination primary onClick={() => clickToCopy(inputs?.destinations[index] + 'destinationText')}>Copy</CopyButtonDestination>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ 'marginLeft': 'auto' }}>
                                                                        <DelteButtonSmall style={{ "float": "right" }} data={index} onClick={(event)=> deleteDestination(event,'fogDestinations')} disabled={(fogReadOnly && isViewPage || !fogReadOnly && !isViewPage)}>Delete</DelteButtonSmall>
                                                                    </div>
                                                                </div> :
                                                                <div>
                                                                    <LeftDiv style={{ "width": "80%", 'paddingTop': '5px', 'marginBottom': '10px', 'display': 'flex' }}>
                                                                        <LabelDestination1 id={inputs?.destinations[index] + 'destinationText'} style={{ 'fontSize': '13px', 'color': 'rgba(0,0,0,0.87)', 'whiteSpace': 'nowrap', 'overflow': 'hidden', 'textOverflow': 'ellipsis', 'maxWidth': '300px' }}>{inputs?.destinations[index]}</LabelDestination1>&nbsp;
                                                                        <div style={{ 'display': 'flex' }}>
                                                                            <LabelDestination1 style={{ 'fontSize': '13px'}}>{" (" + inputs["endpoint_type"] + ")"}</LabelDestination1>
                                                                            <CopyButtonDestination primary onClick={() => clickToCopy(inputs?.destinations[index] + 'destinationText')}>Copy</CopyButtonDestination>
                                                                        </div>
                                                                    </LeftDiv>
                                                                    <ButtonSmall style={{ "float": "right", 'marginRight': '0', 'padding': '0.3em 0.9em' }} data={index} onClick={(event)=> deleteDestination(event,'fogDestinations')} disabled={(fogReadOnly && isViewPage || !fogReadOnly && !isViewPage)}>Delete</ButtonSmall>
                                                                </div>}
                                                        </div>)
                                                })
                                                }
                                                {(inputs?.destinations === undefined || inputs?.destinations.length === 0) &&
                                                    <LabelDestination style={{ 'fontSize': '13px', 'color': 'rgba(0,0,0,0.87)' }}>Destination list is empty</LabelDestination>
                                                }
                                            </List>
                                            {isMobileBrowser && <HR />}
                                        </Row>
                                    </Row>
                                </Row>
                                <Row>
                                    <Column style={{ width: '100%' }}>
                                        <div style={{ "paddingTop": "100px", "color": "gray", "fontSize": "0.9em", "marginLeft": '-11px' }}>
                                            <Label style={{ "fontWeight": "bold", "marginRight": "10px" }}>Special Addresses</Label>
                                            {Object.keys(Constants.FOG_BROADCASTER_ALIASES).map((index) => {
                                                return (
                                                    <div style={{ "display": "flex" }}>
                                                        <Label> {Constants.FOG_BROADCASTER_ALIASES[index]}</Label>
                                                    </div>
                                                )
                                            })
                                            }
                                        </div>
                                    </Column>
                                </Row>
                            </ColumnFog>
                            <Row>
                                <ColumnFog>
                                    <div style={{ 'marginTop': '28px' }}>
                                        <Label>Stream ID</Label>
                                        <Label style={{ "float": "right", "fontSize": "0.9em" }}>(auto - same as Stream Name)</Label>
                                    </div>
                                    <TextField name="stream_id" type="text" data="broadcaster0" disabled={fogReadOnly && isViewPage} maxLength="100" onChange={handleOnChange} value={inputs?.stream_id} />
                                    <div style={{ 'marginLeft': '-2px' }}>
                                        <fieldset style={{ "marginTop": "15px" }}>
                                            <legend>
                                                JPEG Compression
                                            </legend>
                                            <SliderDiv>
                                                <LeftDivToggle style={{ "width": "40%", "marginTop": "25px" }}>
                                                    <SelectionControl id="compression" name="compression" type="switch" label={inputs?.compression === true ? "Enabled" : "Disabled"} labelBefore="true" checked={inputs?.compression || false} onChange={handleToggleChange} />
                                                </LeftDivToggle>
                                                <RightDiv style={{ "width": "60%" }}>
                                                    <Slider name="compression_quality" label={"Quality " + inputs?.compression_quality + "%"} discrete onChange={handleSliderChange} value={parseInt(inputs?.compression_quality)} disabled={fogReadOnly && isViewPage || !inputs?.compression } />
                                                </RightDiv>
                                            </SliderDiv>
                                        </fieldset>
                                        <fieldset>
                                            <legend>
                                                Frame Rate Logging
                                            </legend>
                                            <div style={{ "padding": "12px 0px", "width": "100%", "display": "flex" }}>
                                                <div style={{ "width": "40%", "flex-shrink": "0" }}>
                                                    <SelectionControl id="frame_rate_logging" name="frame_rate_logging" type="switch" label={inputs?.frame_rate_logging === true ? "Enabled" : "Disabled"} labelBefore="true" checked={inputs?.frame_rate_logging || false} onChange={handleToggleChange} />
                                                </div>
                                                <IntervalDiv style={{ "paddingTop": "4px", "width": "100%", "display": "inline-flex", "flex-grow": "1", "align-items": "baseline" }}>
                                                    <Label style={{ flexShrink: '0' }}>Interval (Secs)&nbsp;</Label>
                                                    <div>
                                                        <TextField name="frame_rate_logging_interval" type="text" data="broadcaster0" disabled={fogReadOnly && isViewPage || !inputs?.frame_rate_logging} maxLength="4" onChange={handleFloatOnChange} value={inputs?.frame_rate_logging_interval} />
                                                    </div>
                                                </IntervalDiv>
                                            </div>
                                        </fieldset>
                                    </div>
                                    <div>
                                        <Label>Emit Interval (Secs)</Label>
                                        <Label style={{ "float": "right", "fontSize": "0.9em" }}>(0 - Emit all frames)</Label>
                                    </div>
                                    <TextField name="emit_interval" type="text" data="broadcaster0" disabled={fogReadOnly && isViewPage} maxLength="5" onChange={handleFloatOnChange} value={inputs?.emit_interval} />
                                    <Label style={{ "paddingTop": "10px" }}>Watchdog Timeout (Secs)</Label>
                                    <Label style={{ "float": "right", "fontSize": "0.9em", "paddingTop": "10px" }}>(0 - Disabled)</Label>
                                    <TextField name="watchdog_timeout" type="text" data="broadcaster0" disabled={fogReadOnly && isViewPage} onChange={handleIntegerOnChange} value={inputs?.watchdog_timeout} />
                                    <Row>
                                        <ResponsiveHR />
                                    </Row>
                                </ColumnFog>

                            </Row>
                        </ResponsiveRow>
                        <CenterDiv>
                        <div style={{ 'display': 'inline-flex' }}>
                            {isViewPage &&
                                <div>
                                    {fogReadOnly == true &&
                                        <Button id="editBroadcastererButton" primary onClick={editFogBroadcaster}>Edit</Button>
                                    }
                                    {fogReadOnly == false &&
                                        <div>
                                            <Button primary onClick={(event) => saveBroadcaster(event, "fog_broadcaster1")}>Save</Button>
                                            {initialValues != undefined &&
                                                <Button onClick={cancelBroadcaster}>Cancel</Button>
                                            }
                                        </div>
                                    }
                                </div>
                            }
                            {serviceName.includes("fog_broadcaster1") &&
                                <Button onClick={(event) => resetBroadcaster(event, 'fog_broadcaster1')}>{(isViewPage && initialValues != undefined) ? "Reset" : "Cancel"}</Button>
                            }
                        </div>
                    </CenterDiv>
                    </Row> 
                    {/* } */}

                    {/* { inputs?.legacy_mode === false &&
                        <div>
                            <ResponsiveRow>
                                <Column style={{ 'marginTop': '8px' }}>
                                    <SelectionControl style={{ height: 'auto' }} id="frame_emitter_enabled" type="switch" label={inputs?.frame_emitter_enabled ? "Enabled" : "Disabled"} name="frame_emitter_enabled" labelBefore="true" checked={inputs?.frame_emitter_enabled || false} onChange={handleToggleChange} />
                                    <Column style={{ 'width': '100%', 'padding': '0', 'paddingTop': '15px' }}>
                                        <Label>Stream ID</Label>
                                        <Label style={{ "float": "right", "fontSize": "0.9em" }}>(auto - same as Stream Name)</Label>
                                        <TextField name="frame_emitter_stream_id" type="text" data="broadcaster0" disabled={(frameEmmiterReadOnly && isViewPage) || inputs?.frame_emitter_enabled === false} maxLength="100" onChange={handleOnChange} value={inputs?.frame_emitter_stream_id} />
                                    </Column>
                                    <Column style={{ 'width': '100%', 'padding': '0', 'paddingTop': '15px' }}>
                                        <Row style={{ 'width': '100%' }}>
                                            <Label>Destinations</Label>
                                            {isMobileBrowser ?
                                                <div style={{ 'width': '100%', 'display': 'flex' }}>
                                                    <div style={{ 'width': '75%' }}>
                                                        <TextField name="fm_destination_text" type="text" disabled={(frameEmmiterReadOnly && isViewPage) || inputs?.frame_emitter_enabled === false} onChange={handleOnChange} value={inputs?.fm_destination_text} />
                                                    </div>
                                                    <div style={{ 'marginLeft': 'auto' }}>
                                                        <Button primary style={{ "float": "right", 'margin': '0' }} onClick={addFrameEmitterDestination} disabled={(frameEmmiterReadOnly && isViewPage) || inputs?.frame_emitter_enabled === false}>Add</Button>
                                                    </div>
                                                </div> :
                                                <div style={{ 'width': '100%' }}>
                                                    <LeftDiv style={{ "width": "85%", padding: '0' }}>
                                                        <TextField name="fm_destination_text" type="text" disabled={(frameEmmiterReadOnly && isViewPage) || inputs?.frame_emitter_enabled === false} onChange={handleOnChange} value={inputs?.fm_destination_text} />
                                                    </LeftDiv>
                                                    <RightDiv style={{ "width": "15%", "marginTop": "-1em" }}>
                                                        <Button primary style={{ 'float': 'right' }} onClick={addFrameEmitterDestination} disabled={(frameEmmiterReadOnly && isViewPage) || inputs?.frame_emitter_enabled === false}>Add</Button>
                                                    </RightDiv>
                                                </div>}
                                        </Row>
                                        <Row style={{ 'width': '100%', 'marginBottom': (inputs?.frame_emitter_conn_ep === undefined || inputs?.frame_emitter_conn_ep.length === 0) ? '10px' : '40px' }}>
                                            <List id="frame_emitter_conn_ep" name="frame_emitter_conn_ep" style={{ "marginTop": "10px", "marginBottom": "10px", 'width': '100%' }}>
                                                {inputs?.frame_emitter_conn_ep !== undefined && Object.keys(inputs?.frame_emitter_conn_ep).map((index) => {
                                                    let isActive = (parseInt(index) === inputs?.frame_emitter_conn_ep.length - 1) ? true : false;
                                                    return (
                                                        <div>
                                                            {isMobileBrowser ?
                                                                <div style={{ 'width': '100%', 'display': 'flex', 'marginTop': '10px' }}>
                                                                    <div style={{ 'display': 'flex' }}>
                                                                        <LabelDestination1 id={inputs?.frame_emitter_conn_ep[index] + 'destinationText'} style={{ 'fontSize': '13px', 'color': 'rgba(0,0,0,0.87)', 'whiteSpace': 'nowrap', 'overflow': 'hidden', 'textOverflow': 'ellipsis', 'maxWidth': '200px' }}>{inputs?.frame_emitter_conn_ep[index]}</LabelDestination1>
                                                                        <div style={{ 'display': 'flex' }}>
                                                                            <LabelDestination1 style={{ 'fontSize': '13px' }}>{" (" + inputs["endpoint_type"] + ")"}</LabelDestination1>
                                                                            <CopyButtonDestination primary onClick={() => clickToCopy(inputs?.frame_emitter_conn_ep[index] + 'destinationText')}>Copy</CopyButtonDestination>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ 'marginLeft': 'auto' }}>
                                                                        <DelteButtonSmall style={{ "float": "right" }} data={index} onClick={(event) => deleteDestination(event, 'frameEmitterDestinations')} disabled={(frameEmmiterReadOnly && isViewPage || !frameEmmiterReadOnly && !isViewPage) || inputs?.frame_emitter_enabled === false}>Delete</DelteButtonSmall>
                                                                    </div>
                                                                </div> :
                                                                <div>
                                                                    <LeftDiv style={{ "width": "80%", 'paddingTop': '5px', 'marginBottom': '10px', 'display': 'flex' }}>
                                                                        <LabelDestination1 id={inputs?.frame_emitter_conn_ep[index] + 'destinationText'} style={{ 'fontSize': '13px', 'color': 'rgba(0,0,0,0.87)', 'whiteSpace': 'nowrap', 'overflow': 'hidden', 'textOverflow': 'ellipsis', 'maxWidth': '300px' }}>{inputs?.frame_emitter_conn_ep[index]}</LabelDestination1>&nbsp;
                                                                        <div style={{ 'display': 'flex' }}>
                                                                            <LabelDestination1 style={{ 'fontSize': '13px' }}>{" (" + inputs["endpoint_type"] + ")"}</LabelDestination1>
                                                                            <CopyButtonDestination primary onClick={() => clickToCopy(inputs?.frame_emitter_conn_ep[index] + 'destinationText')}>Copy</CopyButtonDestination>
                                                                        </div>
                                                                    </LeftDiv>
                                                                    <ButtonSmall style={{ "float": "right", 'marginRight': '0', 'padding': '0.3em 0.9em' }} data={index} onClick={(event) => deleteDestination(event, 'frameEmitterDestinations')} disabled={(frameEmmiterReadOnly && isViewPage || !frameEmmiterReadOnly && !isViewPage) || inputs?.frame_emitter_enabled === false}>Delete</ButtonSmall>
                                                                </div>}
                                                        </div>)
                                                })
                                                }
                                                {(inputs?.frame_emitter_conn_ep === undefined || inputs?.frame_emitter_conn_ep.length === 0) &&
                                                    <LabelDestination style={{ 'fontSize': '13px', 'color': 'rgba(0,0,0,0.87)' }}>Destination list is empty</LabelDestination>
                                                }
                                            </List>
                                            {isMobileBrowser && <HR />}
                                        </Row>
                                        <Row>
                                            <Column style={{ width: '100%' }}>
                                                <div style={{ "paddingTop": "100px", "color": "gray", "fontSize": "0.9em", "marginLeft": '-11px' }}>
                                                    <Label style={{ "fontWeight": "bold", "marginRight": "10px" }}>Special Addresses</Label>
                                                    {Object.keys(Constants.FOG_BROADCASTER_ALIASES).map((index) => {
                                                        return (
                                                            <div style={{ "display": "flex" }}>
                                                                <Label> {Constants.FOG_BROADCASTER_ALIASES[index]}</Label>
                                                            </div>
                                                        )
                                                    })
                                                    }
                                                </div>
                                            </Column>
                                        </Row>
                                    </Column>
                                </Column>
                            </ResponsiveRow>
                            <CenterDiv>
                                <div style={{ 'display': 'inline-flex' }}>
                                    {isViewPage &&
                                        <>
                                            {frameEmmiterReadOnly == true &&
                                                <Button id="editBroadcastererButton" primary onClick={editFrameEmmiter}>Edit</Button>
                                            }
                                            {frameEmmiterReadOnly == false &&
                                                <div>
                                                    <Button primary onClick={(event) => saveBroadcaster(event, "frame_emmiter")}>Save</Button>
                                                    {initialValues != undefined &&
                                                        <Button onClick={cancelBroadcaster}>Cancel</Button>
                                                    }
                                                </div>
                                            }
                                        </>
                                    }
                                    { rtspServ?.services?.recorder1?.frame_emitter_enabled === true &&
                                        <Button onClick={resetFrameEmitter}>{(isViewPage && initialValues != undefined) ? "Reset" : "Cancel"}</Button>
                                    }
                                </div>
                            </CenterDiv>

                        </div>} */}
                </TabPanel>

                <TabPanel tabId="hls">
                    <ResponsiveRow>
                        <Column style={{ 'marginTop': '8px' }}>
                            <SelectionControl style={{ height: 'auto' }} id="hls_enabled" type="switch" label={inputs?.hls_enabled ? "Enabled" : "Disabled"} name="hls_enabled" labelBefore="true" checked={inputs?.hls_enabled || false} onChange={handleToggleChange} />
                            {hlsState != undefined &&
                                <div>
                                    <div style={{ marginTop: '-10px' }}>
                                        <>
                                            <RightDiv>
                                                {hlsState?.hls_url_type === "mDNS" &&
                                                    <Button primary name="hls_url_type" onClick={() => handleHlsLabelOnChange("IP")} className={"small-button"} style={{ "paddingTop": "10px" }}>{hlsState?.hls_url_type}</Button>
                                                }
                                                {hlsState?.hls_url_type === "IP" &&
                                                    <Button primary name="hls_url_type" onClick={() => handleHlsLabelOnChange("mDNS")} className={"small-button"} style={{ "paddingTop": "10px" }}>{hlsState?.hls_url_type}</Button>
                                                }
                                                <Button primary onClick={(event)=>copyAddress(event,'copyHlsUrl')} className={"small-button"}>{copiedText ? 'Copied':'Copy'}</Button>
                                            </RightDiv>
                                            <TextField style={{'backgroundColor':'#f2f2f2'}} name="hlsAddress" type="text" value={hlsState?.hls_url_type === "mDNS" ? hlsState?.peer_address_mdns1 : hlsState?.peer_address_ip1} />
                                        </>
                                    </div>
                                </div>
                            }
                            <Column style={{ 'width': '100%', 'padding': '0', 'paddingTop': '15px' }}>
                                <MandatoryLabelStream>Target Duration</MandatoryLabelStream>
                                <SelectField id='target_duration' name='target_duration' value={inputs?.target_duration} disabled={(hlsReadOnly && isViewPage)} onChange={handleOnChange}>
                                    <SelectOptions items={Constants.TARGET_DURATION} />
                                </SelectField>
                            </Column>
                            <Column style={{ 'width': '100%', 'padding': '0', 'paddingTop': '15px' }}>
                                <MandatoryLabelStream>Playlist Length</MandatoryLabelStream>
                                <SelectField id='playlist_length' name='playlist_length' value={inputs?.playlist_length} disabled={(hlsReadOnly && isViewPage)} onChange={handleOnChange}>
                                    <SelectOptions items={Constants.PLAYLIST_LENGTH} />
                                </SelectField>
                            </Column>
                            <Row>
                                <Column style={{ width: '100%' }}>
                                    <div style={{ "paddingTop": "40px", "color": "gray", "fontSize": "0.9em", "marginLeft": '-11px' }}>
                                        <Label><b>Target duration: </b> HLS segment length, increasing this value improves reliability but may increase latency. </Label>
                                        <Label> <b>Playlist length: </b> Max segments in HLS playlist, increasing this value improves reliability but may increase latency. </Label>
                                    </div>
                                </Column>
                            </Row>
                        </Column>
                    </ResponsiveRow>
                    <CenterDiv>
                        <div style={{ 'display': 'inline-flex' }}>
                            {isViewPage &&
                                <>
                                    {hlsReadOnly == true &&
                                        <Button id="editBroadcastererButton" primary onClick={editHlsBroadcaster}>Edit</Button>
                                    }
                                    {hlsReadOnly == false &&
                                        <div>
                                            <Button primary onClick={(event) => saveBroadcaster(event, "hls_broadcaster1")}>Save</Button>
                                            {initialValues != undefined &&
                                                <Button onClick={cancelBroadcaster}>Cancel</Button>
                                            }
                                        </div>
                                    }
                                </>
                            }
                        </div>
                    </CenterDiv>
                </TabPanel>

                <TabPanel tabId="rtmp">
                    <ResponsiveRow>
                        <Column style={{ 'marginTop': '8px' }}>
                            <SelectionControl style={{ height: 'auto' }} id="rtmp_enabled" type="switch" label={inputs?.rtmp_enabled ? "Enabled" : "Disabled"} name="rtmp_enabled" labelBefore="true" checked={inputs?.rtmp_enabled || false} disabled={isViewPage && serviceName.includes("rtmp_broadcaster1")} onChange={handleToggleChange} />
                            <Column style={{ 'width': '100%', 'padding': '0', 'paddingTop': '15px' }}>
                                <MandatoryLabelStream>Stream URL</MandatoryLabelStream>
                                <TextField name="rtmp_url" type="text" data="broadcaster0" value={inputs?.rtmp_url} onChange={handleOnChange} disabled={rtmpReadOnly && isViewPage} />
                            </Column>
                            <Column style={{ 'width': '100%', 'padding': '0', 'paddingTop': '15px' }}>
                                <Label>Stream Key</Label>
                                <TextField name="stream_key" type="text" data="broadcaster0" value={inputs?.stream_key} onChange={handleOnChange} disabled={rtmpReadOnly && isViewPage} maxLength="100" />
                            </Column>
                        </Column>
                    </ResponsiveRow>
                    <Row>
                        <CenterDiv>
                            <div style={{ 'display': 'inline-flex' }}>
                                {isViewPage &&
                                    <div>
                                        {rtmpReadOnly === true &&
                                            <Button id="editBroadcastererButton" primary onClick={editRtmpBroadcaster}> Edit </Button>
                                        }
                                        {rtmpReadOnly === false && (
                                            <div>
                                                <Button primary onClick={(event) => saveBroadcaster(event, 'rtmp_broadcaster1')}>Save </Button>
                                                {initialValues !== undefined && (
                                                    <Button onClick={cancelBroadcaster}>Cancel</Button>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                }
                                {serviceName.includes("rtmp_broadcaster1") &&
                                    <Button onClick={(event) => resetBroadcaster(event, 'rtmp_broadcaster1')}>{(isViewPage && initialValues != undefined) ? "Reset" : "Cancel"}</Button>
                                }
                            </div>
                        </CenterDiv>
                    </Row>
                </TabPanel>

                <TabPanel tabId="rtp">
                    <ResponsiveRow>
                        <Column style={{ 'marginTop': '8px' }}>
                            <SelectionControl style={{ height: 'auto' }} id="rtp_enabled" type="switch" label={inputs?.rtp_enabled ? "Enabled" : "Disabled"} name="rtp_enabled" labelBefore="true" checked={inputs?.rtp_enabled || false} disabled={isViewPage && serviceName.includes("rtp_broadcaster1")} onChange={handleToggleChange} />
                            <Column style={{ 'width': '100%', 'padding': '0', 'paddingTop': '15px' }}>
                                <MandatoryLabelStream>Server IP</MandatoryLabelStream>
                                <TextField name="rtp_server_ip" type="text" data="broadcaster0" value={inputs?.rtp_server_ip} onChange={handleOnChange} disabled={rtpReadOnly && isViewPage} maxLength="100" />
                            </Column>
                            <>
                                <Column style={{ 'width': '100%', 'padding': '0', 'paddingTop': '4px' }}>
                                    <MandatoryLabelBroadcaster style={{ marginBottom: '3px' }}>Video Port</MandatoryLabelBroadcaster>
                                    <TextField name="rtp_video_udp_port" type="text" data="broadcaster0" value={inputs?.rtp_video_udp_port} onChange={handlePortOnChange} disabled={rtpReadOnly && isViewPage} maxLength="5" />
                                </Column>
                                <Column style={{ 'width': '100%', 'padding': '0', 'paddingTop': '15px' }}>
                                    <Label>Audio Port</Label>
                                    <TextField name="rtp_audio_udp_port" type="text" data="broadcaster0" value={inputs?.rtp_audio_udp_port} onChange={handlePortOnChange} disabled={rtpReadOnly && isViewPage} maxLength="5" />
                                </Column>
                            </>
                        </Column>
                    </ResponsiveRow>
                    <CenterDiv>
                        <div style={{ 'display': 'inline-flex' }}>
                            {isViewPage &&
                                <div>
                                    {rtpReadOnly == true &&
                                        <Button id="editBroadcastererButton" primary onClick={editRtpBroadcaster}>Edit</Button>
                                    }
                                    {rtpReadOnly == false &&
                                        <div>
                                            <Button primary onClick={(event) => saveBroadcaster(event, "rtp_broadcaster1")}>Save</Button>
                                            {initialValues != undefined &&
                                                <Button onClick={cancelBroadcaster}>Cancel</Button>
                                            }
                                        </div>
                                    }
                                </div>
                            }
                            {serviceName.includes("rtp_broadcaster1") &&
                                <Button onClick={(event) => resetBroadcaster(event, 'rtp_broadcaster1')}>{(isViewPage && initialValues != undefined) ? "Reset" : "Cancel"}</Button>
                            }
                        </div>
                    </CenterDiv>
                </TabPanel>

                <TabPanel tabId="kinesis">
                    <ResponsiveRow>
                        <Column style={{ 'marginTop': '8px' }}>
                            <SelectionControl style={{ height: 'auto' }} id="kinesis_enabled" type="switch" label={inputs?.kinesis_enabled ? "Enabled" : "Disabled"} name="kinesis_enabled" labelBefore="true" checked={inputs?.kinesis_enabled || false} disabled={isViewPage && serviceName.includes("kinesis_streamer1")} onChange={handleToggleChange} />
                            <Column style={{ 'width': '100%', 'padding': '0', 'marginTop': '15px' }}>
                                <MandatoryLabelStream>Stream Name</MandatoryLabelStream>
                                <TextField name="kinesis_streamer_stream_name" type="text" data="broadcaster0" value={inputs?.kinesis_streamer_stream_name} disabled={kinesisReadOnly && isViewPage} onChange={handleOnChange} maxLength="100" />
                            </Column>
                            <Column style={{ 'width': '100%', 'padding': '0', 'marginTop': '5px' }}>
                                <MandatoryLabelBroadcaster style={{ 'marginBottom': '-5px' }}>Region Name</MandatoryLabelBroadcaster>
                                <SelectField id="kinesis_streamer_region_name" defaultValue={"ap-northeast-1"} name="kinesis_streamer_region_name" style={{ 'margin-top': '10px', 'flexGrow': '1', }} value={inputs?.kinesis_streamer_region_name} disabled={kinesisReadOnly && isViewPage} onChange={handleOnChange}>
                                    <SelectOptions items={Constants.AWS_REGIONS} />
                                </SelectField>
                            </Column>
                            <Column style={{ 'width': '100%', 'padding': '0', 'marginTop': '13px' }}>
                                <MandatoryLabelStream>Access Key</MandatoryLabelStream>
                                <TextField name="kinesis_streamer_access_key_id" type="text" data="broadcaster0" value={inputs?.kinesis_streamer_access_key_id} disabled={kinesisReadOnly && isViewPage} onChange={handleOnChange} maxLength="100" />
                            </Column>
                            <Column style={{ 'width': '100%', 'padding': '0', 'marginTop': '13px' }}>
                                <MandatoryLabelStream>Secret Key</MandatoryLabelStream>
                                <TextField style={{ 'marginTop': '-2px' }} name="kinesis_streamer_secret_access_key" type="text" data="broadcaster0" value={inputs?.kinesis_streamer_secret_access_key} disabled={kinesisReadOnly && isViewPage} onChange={handleOnChange} maxLength="100" />
                            </Column>
                        </Column>
                    </ResponsiveRow>
                    <CenterDiv>
                        <div style={{ 'display': 'inline-flex' }}>
                            {isViewPage &&
                                <div>
                                    {kinesisReadOnly === true &&
                                        <Button id="editBroadcastererButton" primary onClick={editKinesisBroadcaster}>Edit</Button>
                                    }
                                    {kinesisReadOnly === false && (
                                        <div>
                                            <Button primary onClick={(event) => saveBroadcaster(event, 'kinesis_streamer1')}>Save</Button>
                                            {initialValues !== undefined && (
                                                <Button onClick={cancelBroadcaster}>Cancel</Button>
                                            )}
                                        </div>
                                    )}
                                </div>
                            }
                            {serviceName.includes("kinesis_streamer1") &&
                                <Button onClick={(event) => resetBroadcaster(event, 'kinesis_streamer1')}>{(isViewPage && initialValues != undefined) ? "Reset" : "Cancel"}</Button>
                            }
                        </div>
                    </CenterDiv>
                </TabPanel>

                <TabPanel tabId="WebRTC">
                    <ResponsiveRow>
                        <Column style={{ 'marginTop': '8px' }}>
                            <SelectionControl style={{ height: 'auto' }} id="webrtc_enabled" type="switch" label={inputs?.webrtc_enabled ? "Enabled" : "Disabled"} name="webrtc_enabled" labelBefore="true" checked={inputs?.webrtc_enabled || false} disabled={true} onChange={handleToggleChange} />

                            {inputs != undefined &&
                                <div style={{ marginTop: '-10px' }}>
                                    <RightDiv>
                                        {webrtcState?.webrtc_url_type === "mDNS" &&
                                            <Button primary name="webrtc_url_type" onClick={() => handleWebrtcLabelOnChange("IP")} className={"small-button"} style={{ "paddingTop": "10px" }}>{webrtcState?.webrtc_url_type}</Button>
                                        }
                                        {webrtcState?.webrtc_url_type === "IP" &&
                                            <Button primary name="webrtc_url_type" onClick={() => handleWebrtcLabelOnChange("mDNS")} className={"small-button"} style={{ "paddingTop": "10px" }}>{webrtcState?.webrtc_url_type}</Button>
                                        }
                                        <Button primary onClick={(event)=>copyAddress(event,'copyWebRTCUrl')} className={"small-button"}>{copiedText ? 'Copied':'Copy'}</Button>
                                    </RightDiv>
                                </div>
                            }
                            <TextField style={{'backgroundColor':'#f2f2f2'}} name="webRTCAddress" type="text" value={inputs?.webrtc_enabled ? (webrtcState?.webrtc_url_type === "mDNS" ? webrtcState?.peer_address_mdns : webrtcState?.peer_address_ip) : ''} />
                        </Column>
                    </ResponsiveRow>
                    <CenterDiv>
                        <div style={{ 'display': 'inline-flex' }}>
                            {isViewPage &&
                                <div>
                                    <EditWEBRTCButton id="editBroadcastererButton" primary>Edit</EditWEBRTCButton>
                                </div>
                            }
                        </div>
                    </CenterDiv>
                </TabPanel>

                <TabPanel tabId="rtsp">
                    <ResponsiveRow>
                        <Column style={{ 'marginTop': '8px' }}>
                            <SelectionControl style={{ height: 'auto' }} id="rtsp_enabled" type="switch" label={inputs?.rtsp_enabled ? "Enabled" : "Disabled"} name="rtsp_enabled" labelBefore="true" checked={inputs?.rtsp_enabled || false} onChange={handleToggleChange} />
                            {inputs != undefined &&
                                <div style={{ marginTop: '-10px' }}>
                                    <RightDiv>
                                        {rtspState?.rtsp_url_type === "mDNS" &&
                                            <Button primary name="rtsp_url_type" onClick={() => handleRtspLabelOnChange("IP")} className={"small-button"} style={{ "paddingTop": "10px" }}>{rtspState?.rtsp_url_type}</Button>
                                        }
                                        {rtspState?.rtsp_url_type === "IP" &&
                                            <Button primary name="rtsp_url_type" onClick={() => handleRtspLabelOnChange("mDNS")} className={"small-button"} style={{ "paddingTop": "10px" }}>{rtspState?.rtsp_url_type}</Button>
                                        }
                                        <Button primary onClick={(event)=>copyAddress(event,'copyRtspUrl')} className={"small-button"}>{copiedText ? 'Copied' : 'Copy'}</Button>
                                    </RightDiv>
                                </div>
                            }
                            <TextField style={{'backgroundColor':'#f2f2f2'}} name="rtspAddress" type="text" value={rtspState?.rtsp_url_type === "mDNS" ? rtspState?.peer_address_mdns : rtspState?.peer_address_ip} />
                        </Column>
                    </ResponsiveRow>
                    <CenterDiv>
                        <div style={{ 'display': 'inline-flex' }}>
                            {isViewPage &&
                                <>
                                    {rtspReadOnly == true &&
                                        <Button id="editBroadcastererButton" primary onClick={editRtspBroadcaster}>Edit</Button>
                                    }
                                    {rtspReadOnly == false &&
                                        <div>
                                            <Button primary onClick={(event) => saveBroadcaster(event, "rtsp_enabled")}>Save</Button>
                                            {initialValues != undefined &&
                                                <Button onClick={cancelBroadcaster}>Cancel</Button>
                                            }
                                        </div>
                                    }
                                </>
                            }
                        </div>
                    </CenterDiv>
                </TabPanel>

                
            </Tabs>
        </TabPanel>)
    }

export default BroadcastingTab;
