import React, { useEffect, useState } from 'react';
import { ContentDiv, Column, Row, Label, TextField, RightDiv, CenterDiv, Th, Td, Button, PopupContainer, PopupButtons, SectionHeader, SectionLabel, Wrapper, MessageLabel, SelectField, overlayStyle } from '../utils/Styles';
import { SelectionControl ,CircularProgress} from 'react-md';
import styled from 'styled-components';
import * as Constants from '../utils/Constants';
import {getFormattedDate} from '../utils/Utils';
import swal from 'sweetalert';
import axios from 'axios';
import Loader from './Loader';
import SelectOptions from './SelectOptions';
import { Tabs, Tab, TabList, TabPanel, TabProvider } from 'react-web-tabs';
import Popup from 'reactjs-popup';
//import { Details } from '@material-ui/icons';
import { formatDistance } from 'date-fns';
import {convertToTimeZone} from 'date-fns-timezone';
import axiosRetry from 'axios-retry';



const FieldContainer = styled.div`
  display: flex;
  padding-top: 0px;
  margin-bottom: 10px;
`;

const SmallTextField = styled(TextField)`
  text-align:center;
  width: 60px;
`;

const MessageContainer = styled.div`
  margin-bottom: 15px;
`;

const Fieldset = styled.fieldset`
  width: 760px;
  padding: 10px 20px;
  margin-top: 20px;
  margin-left: 0px;
  @media screen and (max-width: 1100px) {
    width: 720px;
  }
  @media screen and (max-width: 1000px) {
    width: 600px;
  }
  @media screen and (max-width: 900px) {
    width: 500px;
  }
  @media screen and (max-width: 775px) {
    width: 410px;
  }
  @media screen and (max-width: 515px) {
    width: 350px;
  }
  @media screen and (max-width: 450px) {
    width: 275px;
  }
  @media screen and (max-width: 400px) {
    width: 250px;
  }
`;

const TabWrapper = styled.div`
  padding: 10px 20px;
  margin-left: 20px;
`;

const ButtonContainer = styled.div`
  width: 760px;
  display: flex;
  justify-content: flex-end;
  @media screen and (max-width: 1100px) {
    width: 720px;
  }
  @media screen and (max-width: 1000px) {
    width: 600px;
  }
  @media screen and (max-width: 900px) {
    width: 500px;
  }
  @media screen and (max-width: 775px) {
    width: 410px;
  }
  @media screen and (max-width: 515px) {
    width: 350px;
  }
  @media screen and (max-width: 450px) {
    width: 275px;
  }
  @media screen and (max-width: 400px) {
    width: 250px;
  }
`;

const SelectFieldSmall = styled(SelectField)`
  width: 60px;
`;

const SmallLabel = styled(Label)`
  margin-top: 3px;
  width: 60px;
`;

const Container = styled.div`
  padding: 0px 20px 10px 20px;
`;

const TableContainer = styled.div`
  white-space: nowrap;
`;

const TableTooltip = styled(Td)`
    text-align: center;
    padding: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 205px;
    @media screen and (max-width: 1000px) {
        max-width: 110px;
    }
    @media screen and (max-width: 500px) {
        max-width: 95px;
    }
`;

const TableLayout = styled(TableContainer)`
  margin-top: 10px;
  width: 760px;
  @media screen and (max-width: 1100px) {
    width: 720px;
  }
  @media screen and (max-width: 1000px) {
    width: 600px;
  }
  @media screen and (max-width: 900px) {
    width: 500px;
  }
  @media screen and (max-width: 775px) {
    width: 410px;
    overflow-y: auto;
  }
  @media screen and (max-width: 515px) {
    width: 350px;
  }
  @media screen and (max-width: 450px) {
    width: 275px;
  }
  @media screen and (max-width: 400px) {
    width: 250px;
  }
`;

const ResponsivePopup = styled(PopupContainer)`
  width: 820px;
  @media screen and (max-width: 600px) {
    width: 400px;
  }
  @media screen and (max-width: 500px) {
    width: 365px;
  }
  @media screen and (max-width: 450px) {
    width: 330px;
  }
  @media screen and (max-width: 400px) {
    width: 300px;
    padding: 5px;
  }
`;

const ResponsiveWrapper = styled(Wrapper)`
  @media screen and (max-width: 700px) {
    font-size: 0.9rem;
  }
  @media screen and (max-width: 450px) {
    font-size: 0.75rem;
  }
  @media screen and (max-width: 390px) {
    font-size: 0.68rem;
  }
`;

const LabelText = styled.label`
  
  display:inline-block;
  @media screen and (max-width: 400px) {
    margin-top:2.5px;
  }
`;

const UploaderSettings = () => {
  const [readOnly, setReadOnly] = useState(true);
  const [inputs, setInputs] = useState();
  const [loading, setLoading] = useState(true);
  //const [showUploadStatus, setShowUploadStatus] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [statusDetails, setStatusDetails] = useState();
  const [statusLoading, setStatusLoading] = useState(false);
  const [refreshLoad, setRefreshLoad] = useState(false);
  const [timezone, setTimeZone] = useState('');
  const [retry,setRetry]=useState(false)

  const contentStyle = {
    margin: 'auto',
    background: 'rgb(255, 255, 255)',
    width: 'fit-content',
    height: 'auto',
    padding: '5px',
    minWidth: '25%',
    border: '2px solid #d7d7d7'
  };

  const closePopup = (event) => {
    setModalOpen(false);
  };

  const handleToggleChange = (value, event) => {
    const id = event.target.id;
    if (!readOnly) {
      setInputs({ ...inputs, [id]: value });
    }
  };

  const handleCheckBoxChange = (event) => {
    if (!readOnly && inputs.upload_enabled) {
      setInputs({ ...inputs, [event.target.name]: event.target.checked});
    }
  };

  const getSettings = () => {
    const client = axios.create({ baseURL: Constants.EDGE_API_ENDPOINT });
    axiosRetry(client,{
      retries: 15,
      retryDelay: (retryCount, error) => {
        if(retryCount < 15 && retryCount > 5) {
          setRetry(true)
       } else if(retryCount === 15) {
         setRetry(false)
         swal('HTTP Error: ' +  error.response.status + ' (' +  error.response.statusText + '). Please check your network.',{icon: 'error'});
       }
          return 3000;
      },
      retryCondition: (error) => {
          return true;
      },
    });
    client.get('/system/services/uploader')
      .then(res => {
        const result = res.data.data;
        if(result.images_data_retention_days.length === 2 && result.images_data_retention_days !== '00'){
          result.images_data_retention_days = result.images_data_retention_days.replace(/^0+/, '');
        }
        if(result.texts_data_retention_days.length === 2 && result.texts_data_retention_days !== '00'){
          result.texts_data_retention_days =  result.texts_data_retention_days.replace(/^0+/, '');
        }
        if(result.videos_data_retention_days.length === 2 && result.videos_data_retention_days !== '00'){
          result.videos_data_retention_days = result.videos_data_retention_days.replace(/^0+/, '');
        }
        if(result.images_data_retention_days.length === 2 && result.images_data_retention_days === '00'){
          result.images_data_retention_days = result.images_data_retention_days.replace(/^0+/, '0');
        }
        if(result.texts_data_retention_days.length === 2 && result.texts_data_retention_days === '00'){
          result.texts_data_retention_days =  result.texts_data_retention_days.replace(/^0+/, '0');
        }
        if(result.videos_data_retention_days.length === 2 && result.videos_data_retention_days === '00'){
          result.videos_data_retention_days = result.videos_data_retention_days.replace(/^0+/, '0');
        }
        setInputs(result);
        setLoading(false);
      }).catch(error => {
        if (error.response) {
          var errorObj = error.response.data;
          swal('Error Code: ' + errorObj.error.code + '\nError Message: ' + errorObj.error.message, { icon: 'error' });
        } else {
          swal({ text: 'Unable to connect to the edge-api service', icon: 'error' });
        }
      });
  };

  const editSettings = () => {
    setReadOnly(false);
  };

  const editRetention = () => {
    setModalOpen(true);
  };

  const getStatus = () => {
    setStatusLoading(true);
    setRefreshLoad(true);
    const client = axios.create({ baseURL: Constants.EDGE_API_ENDPOINT });
    axiosRetry(client,{
      retries: 15,
      retryDelay: (retryCount, error) => {
        if(retryCount < 15 && retryCount > 5) {
          setRetry(true)
       } else if(retryCount === 15) {
         setRetry(false)
         swal('HTTP Error: ' +  error.response.status + ' (' +  error.response.statusText + '). Please check your network.',{icon: 'error'});
       }
          return 3000;
      },
      retryCondition: (error) => {
          return true;
      },
    });
    client.get('/system/system_services/uploader/status')
    .then(res => {
      if(res.data.data.env_variables) {
        const tt = res.data.data.env_variables.filter(/./.test, /TZ/)
        const tz = tt[0].split('=');
        setTimeZone(tz[1]);
      } else {
        setTimeZone('Asia/Tokyo');
      }
      setStatusDetails(res.data.data.health_status.status_detail);
      setStatusLoading(false);
      setRefreshLoad(false);
    }).catch(error => {
      if (error.response) {
        var errorObj = error.response.data;
        setStatusLoading(false);
        setRefreshLoad(false);
        console.log(errorObj);
      }
    });
  };

  const saveSettings = () => {
    let jsonData = Object.assign({}, inputs);
    if (!inputs.upload_enabled) { 
      swal({
        text: 'Disabling this feature will have an impact the VPN status on the Cloud and affect all applications on the device which are using the Cloud Uploader. Are you sure you want to continue?',
        buttons: ['No', 'Yes'],
        dangerMode: true,
        icon: 'warning',
      }).then((save) => {
        if(save) {
          axios.patch(Constants.EDGE_API_ENDPOINT + '/system/services/uploader', jsonData)
          .then(res => {
            if(res.data.status_code === '10000'){
              swal('Cloud uploader settings updated successfully.', { icon: 'success' });
              setReadOnly(true);
              setTimeout(() => { window.location.reload(); }, 2000);
            } else {
              swal(res.data.message, { icon: 'error' });
            }
          }).catch(error => {
            if (error.response) {
              var errorObj = error.response.data;
              swal('Error Code: ' + errorObj.error.code + '\nError Message: ' + errorObj.error.message, { icon: 'error' });
            } else {
              swal({ text: 'Unable to connect to the edge-api service', icon: 'error' });
            }
          });
        } else {
          window.location.reload();
          // return;
        }
      })
    } else {
      axios.patch(Constants.EDGE_API_ENDPOINT + '/system/services/uploader', jsonData)
          .then(res => {
            if(res.data.status_code === '10000'){
              swal('Cloud uploader settings updated successfully.', { icon: 'success' });
              setReadOnly(true);
              setTimeout(() => { window.location.reload(); }, 2000);
            } else {
              swal(res.data.message, { icon: 'error' });
            }
          }).catch(error => {
            if (error.response) {
              var errorObj = error.response.data;
              swal('Error Code: ' + errorObj.error.code + '\nError Message: ' + errorObj.error.message, { icon: 'error' });
            } else {
              swal({ text: 'Unable to connect to the edge-api service', icon: 'error' });
            }
          });
    } 
  };

  const cancelSettings = () => {
    window.location.reload();
  };

  useEffect(() => {
    getSettings();
    getStatus();
  }, []);

  // const onTabChange = (tabId) => {
  //   if (tabId === 'general') {
  //     setShowUploadStatus(true);
  //   } else {
  //     setShowUploadStatus(false);
  //   }
  // }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0 || bytes === '0') return 'None';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KiB', 'MiB', 'GiB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    if (i < sizes.length) {
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    } else {
        return parseFloat((bytes / Math.pow(k, 3)).toFixed(dm)) + ' ' + sizes[3];
    }
}

  const getTimeSince = (date) => {
   if(date === '0') {
     return '';
   } else {
     try {
       if (date) {
         var newStr = date.split(' ');
         let formattedDate = newStr[0] + ' ' + newStr[1];
         const zonedTargetDate = convertToTimeZone(new Date(), { timeZone: timezone });
         const result = formatDistance(
           new Date(formattedDate), zonedTargetDate,
           { addSuffix: true, includeSeconds: true }
         );
         return ' (' + result + ')';
       }
     } catch (err) {
       console.log(err);
     }
     return '';
   }
 };

  const refreshretention = (event) => {
    setRefreshLoad(true);
    document.getElementById('btnRefrehRetention').textContent = 'Refreshing...';
    getStatus();
  };

  useEffect(() => {
    if (document.getElementById('btnRefrehRetention')) {
      document.getElementById('btnRefrehRetention').textContent = 'Refresh';
    }
  }, [statusDetails]);

  return (
    <ContentDiv>
      <ResponsiveWrapper>
        <SectionHeader>
          <SectionLabel>Cloud Uploader</SectionLabel>
        </SectionHeader>
        <>
        {/* <TabProvider defaultTab='general' onChange={(tabId) => onTabChange(tabId)}>
        <TabList style={{'padding-left': '15px'}}>
          <Tab tabFor="general" className={'rwt__tab'}>General</Tab>
              <Tab tabFor="schedule" className={'rwt__tab'}>Schedule</Tab>
        </TabList> */}
          { loading &&
        <div className="loading-msg">
          <label>{retry ? 'Retrying...' : 'Loading...'}</label>
          <CircularProgress />
        </div>
            }       
             {/* <TabPanel tabId="general"> */}
        { inputs &&
          <Row style={{'padding': '0px 20px'}}>
            <Fieldset>
              <legend>Uploading Data to the Cloud</legend>
              <FieldContainer style={{'flex-direction': 'column'}}>
                <div>
                  <SelectionControl id="upload_enabled" type="switch" label={inputs.upload_enabled === true ? 'Enabled' : 'Disabled'} name="upload_enabled" labelBefore="true" checked={inputs.upload_enabled} onChange={handleToggleChange} />
                </div>
                <div>
                  <MessageContainer>
                    <MessageLabel style={{'text-size-adjust': '100%'}}>Disabling upload will forbid to upload all kinds of data (even the device status information) to the cloud. If you only want to stop uploading video files and/or image files, you can use the checkboxes below.</MessageLabel>
                  </MessageContainer>
                </div>
                <div>
                  <Label>Data Type Selection</Label>
                  <div style={{'display': 'flex', 'flex-direction': 'row', 'margin-top': '5px'}}>
                    <div style={{'width': '150px','display': 'flex'}}>
                      <input type="checkbox" id="texts_enabled" name="texts_enabled" value={inputs.texts_enabled} checked={inputs.texts_enabled} style={{'opacity': '0.4'}}/>
                      <LabelText style={{'color': 'gray'}}>Status/Log</LabelText>
                    </div>
                    <div style={{'width': '150px','display': 'flex'}}>
                      <input type="checkbox" id="images_enabled" name="images_enabled" value={inputs.images_enabled} checked={inputs.images_enabled} onChange={handleCheckBoxChange} style={{'opacity':  (readOnly === true || inputs.upload_enabled === false)  ? '0.4' : '1.0'}} /><LabelText style={{'color': inputs.upload_enabled === false ?'gray' : ''}}>Image</LabelText>
                    </div>
                    <div style={{'width': '150px','display': 'flex'}}>
                      <input type="checkbox" id="videos_enabled" name="videos_enabled" value={inputs.videos_enabled} checked={inputs.videos_enabled} onChange={handleCheckBoxChange} style={{'opacity': (readOnly === true || inputs.upload_enabled === false) ? '0.4' : '1.0'}} /><LabelText style={{'color': inputs.upload_enabled === false ? 'gray' : ''}}>Video</LabelText>
                    </div>
                  </div>
                </div>
                  <div>
                    <div style={{ 'marginTop': '12px' }}>
                      <SelectionControl id="purge_after_uploading_enabled" type="switch" label="Purge After Uploading" name="purge_after_uploading_enabled" labelBefore="true" checked={inputs.purge_after_uploading_enabled} disabled={inputs.upload_enabled === false } onChange={handleToggleChange} />
                    </div>
                  </div>
                  <div>
                  <MessageContainer>
                    <MessageLabel style={{'text-size-adjust': '100%'}}>By enabling this feature archive data will be removed from archive storage once it is uploaded to cloud.</MessageLabel>
                  </MessageContainer>
                </div>
              </FieldContainer>
            </Fieldset>
            <ButtonContainer>
              {readOnly &&
                <Button id="editSettingsButton" primary onClick={editSettings}>Edit</Button>
              }
              {!readOnly &&
                <div>
                  <Button id="saveSettingsButton" primary onClick={saveSettings}>Save</Button>
                  <Button id="cancelSettingsButton" danger onClick={cancelSettings}>Cancel</Button>
                </div>
              }
            </ButtonContainer>
            </Row> } 
            { inputs &&
            <>
            <Row style={{'padding': '0px 20px', 'display': 'flex', 'flexDirection': 'column'}}>
              <Label>Uploading Status</Label>
              <TableLayout>
                <table style={{ 'width':'100%', 'border-collapse': 'collapse'}}>
                  <tr style={{ 'background-color': '#1f303a', 'color': 'white' }}>
                    <Th style={{'padding': '10px', 'width': '115px'}}>Type</Th>
                    <Th style={{'padding': '10px'}}>Last Upload Time</Th>
                    {/* <Th style={{'padding': '10px', 'width': '150px'}}>Pending Uploads</Th> */}
                    <Th style={{'padding': '10px', 'width': '135px'}}>Retention</Th>
                    <Th style={{'padding': '10px', 'width': '135px'}}>Pending Uploads <br /> Quota </Th>
                  </tr>
                  <tr>
                    <Td style={{'padding': '10px'}}>Status/Log</Td>
                    <TableTooltip title={statusDetails && getTimeSince(statusDetails.last_upload_time.texts)} style={{'padding': '10px', 'textAlign': 'center'}}>
                    <div>{statusDetails ? (statusDetails.last_upload_time.texts !== '' || statusDetails.last_upload_time.texts !== '0' ) ? (statusDetails.last_upload_time.texts === '' ? '-' : statusDetails.last_upload_time.texts ) : '-': '-'}</div>
                    </TableTooltip>
                    
                    <Td>{(((inputs.texts_data_retention_days === '0' || inputs.texts_data_retention_days === '00') ? '' : inputs.texts_data_retention_days.replace(/^0+/, '') + ' days ') + ((inputs.texts_data_retention_hours === '00' ? '' : inputs.texts_data_retention_hours.replace(/^0+/, '') + ' hours')))}</Td>
                    <Td style={{ 'padding': '10px' }}>
                        <div title={statusDetails ?
                          statusDetails.remaining_bytes["priority.texts"] !== '' ? formatBytes(statusDetails.remaining_bytes["priority.texts"]) : 'None' : '-'}
                          style={{ 'paddingBottom': '5px' }}
                        >
                          {statusDetails ?
                            statusDetails.remaining_bytes["priority.texts"] !== '' ? formatBytes(statusDetails.remaining_bytes["priority.texts"]) : 'None' : '-'}
                        </div>
                      <div style={{ 'paddingBottom': '5px' }}>
                        {inputs.texts_quota_enabled === false ? '-' : `${inputs.texts_quota_size} GiB`}
                      </div>
                    </Td>
                  </tr>
                  <tr>
                    <Td style={{'padding': '10px'}}>Image</Td>
                    <TableTooltip title={statusDetails && getTimeSince(statusDetails.last_upload_time.images)} style={{'padding': '10px', 'textAlign': 'center'}}>
                    <div>{statusDetails ?(statusDetails.last_upload_time.images !== '' || statusDetails.last_upload_time.images !== '0' ) ? (statusDetails.last_upload_time.images === '' ? '-' : statusDetails.last_upload_time.images ): '-' : '-'}</div>
                    </TableTooltip>
                    
                    <Td>{(((inputs.images_data_retention_days === '0' || inputs.images_data_retention_days === '00') ? '' : inputs.images_data_retention_days.replace(/^0+/, '') + ' days ') + ((inputs.images_data_retention_hours === '00' ? '' : inputs.images_data_retention_hours.replace(/^0+/, '') + ' hours')))}</Td>
                    <Td style={{ 'padding': '10px' }}>
                      <div title={statusDetails ? statusDetails.remaining_bytes.images === undefined ? 'None' :
                        ((statusDetails.remaining_bytes.images === '' || statusDetails.remaining_bytes.images === undefined) && statusDetails.remaining_bytes["priority.images"] !== '') ?
                          formatBytes(+statusDetails.remaining_bytes["priority.images"]) :
                          (statusDetails.remaining_bytes.images !== '' && statusDetails.remaining_bytes["priority.images"] === '') ?
                            statusDetails.remaining_bytes.images :
                            (statusDetails.remaining_bytes.images === '' && statusDetails.remaining_bytes["priority.images"] === '') ?
                              'None' :
                              (statusDetails.remaining_bytes.images !== '' && statusDetails.remaining_bytes["priority.images"] !== '') ?
                                formatBytes(+statusDetails.remaining_bytes["priority.images"] + +statusDetails.remaining_bytes.images) : 'None' :
                        '-'}
                        style={{ 'paddingBottom': '5px' }}
                      >
                        {statusDetails ? statusDetails.remaining_bytes.images === undefined ? 'None' :
                          ((statusDetails.remaining_bytes.images === '' || statusDetails.remaining_bytes.images === undefined) && statusDetails.remaining_bytes["priority.images"] !== '') ?
                            formatBytes(+statusDetails.remaining_bytes["priority.images"]) :
                            (statusDetails.remaining_bytes.images !== '' && statusDetails.remaining_bytes["priority.images"] === '') ?
                              statusDetails.remaining_bytes.images :
                              (statusDetails.remaining_bytes.images === '' && statusDetails.remaining_bytes["priority.images"] === '') ?
                                'None' :
                                (statusDetails.remaining_bytes.images !== '' && statusDetails.remaining_bytes["priority.images"] !== '') ?
                                  formatBytes(+statusDetails.remaining_bytes["priority.images"] + +statusDetails.remaining_bytes.images) : 'None' :
                          '-'}
                      </div>
                      <div style={{ 'paddingBottom': '5px' }}>
                        {inputs.images_quota_enabled === false ? '-' : `${inputs.images_quota_size} GiB`}
                      </div>
                    </Td>
                  </tr>
                  <tr>
                    <Td style={{'padding': '10px'}}>Video</Td>
                    <TableTooltip title={statusDetails && getTimeSince(statusDetails.last_upload_time.videos) } style={{'padding': '10px', 'textAlign': 'center'}}>
                      <div>{statusDetails ?(statusDetails.last_upload_time.videos !== '' || statusDetails.last_upload_time.videos !== '0' ) ? (statusDetails.last_upload_time.videos === '' ? '-': statusDetails.last_upload_time.videos ) : '-' : '-' }</div>
                    </TableTooltip>
                    <Td>{(((inputs.videos_data_retention_days === '0' || inputs.videos_data_retention_days === '00') ? '' : inputs.videos_data_retention_days.replace(/^0+/, '') + ' days ') + ((inputs.videos_data_retention_hours === '00' ? '' : inputs.videos_data_retention_hours.replace(/^0+/, '') + ' hours')))}</Td>
                    <Td style={{ 'padding': '10px' }}>
                      <div title={statusDetails ? statusDetails.remaining_bytes.videos === '' || statusDetails.remaining_bytes.videos === undefined ? 'None' : formatBytes(statusDetails.remaining_bytes.videos) : '-'} style={{ 'paddingBottom': '5px' }}>
                        {statusDetails ? statusDetails.remaining_bytes.videos === '' || statusDetails.remaining_bytes.videos === undefined ? 'None' : formatBytes(statusDetails.remaining_bytes.videos) : '-'}
                      </div>
                      <div style={{ 'paddingBottom': '5px' }}>
                        {inputs.videos_quota_enabled === false ? '-' : `${inputs.videos_quota_size} GiB`}
                      </div>
                    </Td>                   
                  </tr>
                </table>
              </TableLayout>
              <ButtonContainer style={{'marginTop': '15px'}}>
                  <Button primary id="btnRefrehRetention" name="btnRefrehRetention" disabled={refreshLoad} onClick={() => refreshretention()}>Refresh</Button>
                  <Button id="showStatusButton" primary onClick={editRetention}>Edit</Button> 
                  <Popup modal closeOnDocumentClick={false} open={modalOpen} onClose={closePopup} {...{ contentStyle, overlayStyle }}>
                    <RetentionPopup inputs={inputs} closePopup={closePopup} statusLoading={statusLoading} />
                  </Popup>
              </ButtonContainer>
            </Row> </> }
        {/* </TabPanel>
        <TabPanel tabId='schedule'>
          
        </TabPanel>
      </TabProvider> */}
      </>
      </ResponsiveWrapper>
    </ContentDiv>
  );
};

const RetentionPopup = (props) => {

  const {inputs, statusLoading, closePopup} = props;
  const [popupDetails, setPopupDetails] = useState(inputs);
  const [lengthLimit, setLengthLimit] = useState('');

  const handleOnChange = (event) => {
    event.persist();
    if(event.target.name === 'videos_quota_size' || event.target.name === 'images_quota_size' || event.target.name === 'texts_quota_size'){
      const inputValue = event.target.value;
      const formattedValue = inputValue.replace(/[^0-9.]*/g, '').replace(/(\..*?)\..*/g, '$1').replace(/(\.\d{2})\d*/g, '$1');
      event.target.value = formattedValue;
      const maxLength = formattedValue.includes('.') ? 5 : 4;
      setLengthLimit(maxLength);
    }
    if (event.target.name === 'videos_data_retention_days' || event.target.name === 'images_data_retention_days' || event.target.name === 'texts_data_retention_days') {
      const regEx = /^[0-9][0-9]*$/;
      if (regEx.exec(event.target.value)) {
        setPopupDetails({ ...popupDetails, [event.target.name]: event.target.value });
      } else {
        if (event.target.value === '') {
          setPopupDetails({ ...popupDetails, [event.target.name]: event.target.value });
        }
      }
    } else {
      setPopupDetails({ ...popupDetails, [event.target.name]: event.target.value });
    }
};

  const handleToggleChange = (value, event) => {
    const id = event.target.id;
    setPopupDetails({ ...popupDetails, [id]: value });
  };

  const validateDataRetentionTime = (dataType, days, hours) => {
    if(!days){
      swal('Please enter retention days for ' + dataType + '.', { icon: 'error' });
      return false;
    }

    if ((days === '00' || days === '0') && hours === '00') {
      swal('Data retention time must be >= 1 hour for ' + dataType + '.', { icon: 'error' });
      return false;
    }
    return true;
  };


  const saveSettings = () => {
    let jsonData = Object.assign({}, popupDetails);
    if(!validateDataRetentionTime('status/log', jsonData.texts_data_retention_days, jsonData.texts_data_retention_hours)){
      return;
    }
    if ((jsonData.texts_quota_enabled === true && jsonData.texts_quota_size < 0.1 ) || (jsonData.texts_quota_enabled === true && jsonData.texts_quota_size === '')) {
      swal('Quota size must be >= 0.1 GiB for status/log.', { icon: 'error' });
      return;
    } else if(((jsonData.texts_quota_size).charAt(jsonData.texts_quota_size.length - 1) === '.') || (jsonData.texts_quota_size === '.') ){
      swal('Invalid quota size for status/log.', { icon: 'error' });
      return;
    }

    if(!validateDataRetentionTime('image', jsonData.images_data_retention_days, jsonData.images_data_retention_hours)){
      return;
    }
    if ((jsonData.images_quota_enabled && jsonData.images_quota_size < 0.1 ) || (jsonData.images_quota_enabled && jsonData.images_quota_size === '')) {
      swal('Quota size must be >= 0.1 GiB for image.', { icon: 'error' });
      return;
    } else if(((jsonData.images_quota_size).charAt(jsonData.images_quota_size.length - 1) === '.') || (jsonData.images_quota_size === '.')){
      swal('Invalid quota size for image.', { icon: 'error' });
      return;
    }

    if(!validateDataRetentionTime('video', jsonData.videos_data_retention_days, jsonData.videos_data_retention_hours)){
      return;
    }
    if ((jsonData.videos_quota_enabled && jsonData.videos_quota_size < 0.1 ) || (jsonData.videos_quota_enabled && jsonData.videos_quota_size === '')) {
      swal('Quota size must be >= 0.1 GiB for video.', { icon: 'error' });
      return;
    } else if(((jsonData.videos_quota_size).charAt(jsonData.videos_quota_size.length - 1) === '.') || (jsonData.videos_quota_size === '.')){
      swal('Invalid quota size for video.', { icon: 'error' });
      return;
    }

    if(jsonData.images_data_retention_days.length === 2 && jsonData.images_data_retention_days !== '00'){
      jsonData.images_data_retention_days = jsonData.images_data_retention_days.replace(/^0+/, '');
    }
    if(jsonData.texts_data_retention_days.length === 2 && jsonData.texts_data_retention_days !== '00'){
      jsonData.texts_data_retention_days =  jsonData.texts_data_retention_days.replace(/^0+/, '');
    }
    if(jsonData.videos_data_retention_days.length === 2 && jsonData.videos_data_retention_days !== '00'){
      jsonData.videos_data_retention_days = jsonData.videos_data_retention_days.replace(/^0+/, '');
    }
    if(jsonData.images_data_retention_days.length === 2 && jsonData.images_data_retention_days === '00'){
      jsonData.images_data_retention_days = jsonData.images_data_retention_days.replace(/^0+/, '0');
    }
    if(jsonData.texts_data_retention_days.length === 2 && jsonData.texts_data_retention_days === '00'){
      jsonData.texts_data_retention_days =  jsonData.texts_data_retention_days.replace(/^0+/, '0');
    }
    if(jsonData.videos_data_retention_days.length === 2 && jsonData.videos_data_retention_days === '00'){
      jsonData.videos_data_retention_days = jsonData.videos_data_retention_days.replace(/^0+/, '0');
    }


    axios.patch(Constants.EDGE_API_ENDPOINT + '/system/services/uploader', jsonData)
      .then(res => {
        if(res.data.status_code === '10000'){
          swal('Cloud uploader settings updated successfully.', { icon: 'success' });
          setTimeout(() => { window.location.reload(); }, 2000);
        } else {
          swal(res.data.message, { icon: 'error' });
        }
      }).catch(error => {
        if (error.response) {
          var errorObj = error.response.data;
          swal('Error Code: ' + errorObj.error.code + '\nError Message: ' + errorObj.error.message, { icon: 'error' });
        } else {
          swal({ text: 'Unable to connect to the edge-api service', icon: 'error' });
        }
      });
  };

  return (
    <ResponsivePopup>
       <SectionHeader style={{ 'display': 'flex' }}>
          <SectionLabel>Edit Retention</SectionLabel>
        </SectionHeader>
      <Container>
      {/* <Loader isLoading={statusLoading} /> */}
      {popupDetails && 
        <Row style={{'marginTop':'10px'}}>
          <MessageContainer>
            <MessageLabel style={{'margin':'3px 0 3px 0'}}>Change following configuration to avoid getting local storage full.</MessageLabel>
            <MessageLabel style={{'margin':'3px 0 3px 0'}}><b>Retention</b>:  If local data is not uploaded for this amount of time, the data will expire and be deleted to avoid the local storage full.</MessageLabel>
            <MessageLabel style={{'margin':'3px 0 3px 0'}}><b>Quota</b>: Old data will be removed partially before local storage reaches this capacity.</MessageLabel>
          </MessageContainer>

            <TableLayout>
              <table style={{ 'width': '100%', 'border-collapse': 'collapse', 'marginTop': '10px' }}>
                <tr style={{ 'background-color': '#1f303a', 'color': 'white' }}>
                  <Th style={{ 'padding': '10px', 'width': '115px' }}>Data Type</Th>
                  <Th style={{ 'padding': '10px', 'width': '135px' }}>Retention</Th>
                  <Th style={{ 'padding': '10px', 'width': '135px' }}>Quota</Th>
                </tr>
                <tr>
                  <Td>
                  <SmallLabel style={{'padding': '13px 0px'}}>Status/Log</SmallLabel>
                  </Td>
                  <Td>
                    <SmallTextField id="texts_data_retention_days" name="texts_data_retention_days" value={popupDetails.texts_data_retention_days} onChange={handleOnChange} maxLength="2" />
                    <SmallLabel style={{ 'marginLeft': '0px', 'marginRight': '10px' }}>day(s)</SmallLabel>
                    <SelectFieldSmall id="texts_data_retention_hours" name="texts_data_retention_hours" value={popupDetails.texts_data_retention_hours} onChange={handleOnChange} >
                      <SelectOptions items={Constants.HOURS} />
                    </SelectFieldSmall>
                    <SmallLabel style={{ 'marginLeft': '2px' }}>hour(s)</SmallLabel>
                  </Td>
                  <Td>
                  <div style={{ 'display': 'flex', 'alignItems': 'center', 'justifyContent': 'center' }}>
                    <div style={{'width':'125px'}}><SelectionControl id="texts_quota_enabled" type="switch" label={popupDetails.texts_quota_enabled ? 'Enabled' : 'Disabled'} name="texts_quota_enabled" labelBefore="true" checked={popupDetails.texts_quota_enabled} onChange={handleToggleChange} /></div>
                  <div><SmallTextField style={{'width':'60px'}} id="texts_quota_size" name="texts_quota_size" value={popupDetails.texts_quota_size} disabled={!popupDetails.texts_quota_enabled} maxLength={lengthLimit} onChange={handleOnChange}  /> &nbsp; GiB</div>
                  </div>
                  </Td>
                </tr>
                <tr>
                  <Td>
                    <SmallLabel style={{ 'padding': '13px 0px' }}>Image</SmallLabel>
                  </Td>
                  <Td>
                    <SmallTextField id="images_data_retention_days" name="images_data_retention_days" value={popupDetails.images_data_retention_days} onChange={handleOnChange} maxLength="2" />
                    <SmallLabel style={{ 'marginLeft': '0px', 'marginRight': '10px' }}>day(s)</SmallLabel>
                    <SelectFieldSmall id="images_data_retention_hours" name="images_data_retention_hours" value={popupDetails.images_data_retention_hours} onChange={handleOnChange} >
                      <SelectOptions items={Constants.HOURS} />
                    </SelectFieldSmall>
                    <SmallLabel style={{ 'marginLeft': '5px' }}>hour(s)</SmallLabel>
                  </Td>
                  <Td>
                    <div style={{ 'display': 'flex', 'alignItems': 'center', 'justifyContent': 'center' }}>
                      <div style={{'width':'125px'}}><SelectionControl id="images_quota_enabled" type="switch" label={popupDetails.images_quota_enabled ? 'Enabled' : 'Disabled'} name="images_quota_enabled" labelBefore="true" checked={popupDetails.images_quota_enabled} onChange={handleToggleChange} /></div>
                      <div><SmallTextField id="images_quota_size" name="images_quota_size" value={popupDetails.images_quota_size} disabled={!popupDetails.images_quota_enabled} maxLength={lengthLimit} onChange={handleOnChange} /> &nbsp; GiB</div>
                    </div>
                  </Td>
                </tr>
                <tr>
                  <Td>
                  <SmallLabel style={{'padding': '13px 0px'}}>Video</SmallLabel>
                  </Td>
                  <Td>
                    <SmallTextField id="videos_data_retention_days" name="videos_data_retention_days" value={popupDetails.videos_data_retention_days} onChange={handleOnChange} maxLength="2" />
                    <SmallLabel style={{ 'marginLeft': '2px', 'marginRight': '10px' }}>day(s)</SmallLabel>
                    <SelectFieldSmall id="videos_data_retention_hours" name="videos_data_retention_hours" value={popupDetails.videos_data_retention_hours} onChange={handleOnChange}>
                      <SelectOptions items={Constants.HOURS} />
                    </SelectFieldSmall>
                    <SmallLabel style={{ 'marginLeft': '5px' }}>hour(s)</SmallLabel>
                  </Td>
                  <Td>
                    <div style={{ 'display': 'flex', 'alignItems': 'center', 'justifyContent': 'center' }}>
                      <div style={{'width':'125px'}}><SelectionControl id="videos_quota_enabled" type="switch" label={popupDetails.videos_quota_enabled ? 'Enabled' : 'Disabled'} name="videos_quota_enabled" labelBefore="true" checked={popupDetails.videos_quota_enabled} onChange={handleToggleChange} /></div>
                      <div><SmallTextField style={{ 'width': '60px' }} id="videos_quota_size" name="videos_quota_size" value={popupDetails.videos_quota_size} disabled={!popupDetails.videos_quota_enabled} maxLength={lengthLimit} onChange={handleOnChange} /> &nbsp; GiB</div>
                    </div>
                  </Td>
                </tr>
              </table>
          </TableLayout>

        </Row>}
        <Row style={{'marginTop':'10px'}}>
          <PopupButtons>
            <Button primary onClick={() => {saveSettings()}}>Save</Button>
            <Button id="btnCancelInstance" danger onClick={() => { closePopup(); }}>Cancel</Button>
          </PopupButtons>
        </Row>
      </Container>
    </ResponsivePopup>
  )
}

export default UploaderSettings;
