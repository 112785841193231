import React , { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components';
import axios from 'axios';
import * as Constants from '../utils/Constants';


const DeviceStatusContainer=styled.div`
    padding-right: 15px;
    padding-top: 10px;
    margin-left: 8px;
`;

const TooltipMessage=styled.span`
    font-weight: bold;
    font-size: 1.0em;
    color: black; 
`;

const ReconciliationStatus=styled.a`
    font-weight: bold;
    font-size: 1.2em;
    color: orange;
    cursor: pointer;
`;

const ResetDeviceStatus=styled.a`
    font-weight: bold;
    font-size: 1.2em;
    color: orange;
    cursor: pointer;
`;

const FailedStatus=styled.a`
    font-weight: bold;
    font-size: 1.2em;
    color: red;
    cursor: pointer;
`;

function DeviceHealthStatus(props) {
    const [diskStatus, setDiskStatus] = useState('');
    const [raidStatus, setRaidStatus] = useState('');
    const [swapStatus, setSwapStatus] = useState('');

    useEffect(() => {
        axios.get(Constants.EDGE_API_ENDPOINT + '/system/status')
            .then(res => {
                const { disk, raid, swap } = res.data.data.services;
                if (disk) {
                    setDiskStatus(disk);
                }
                if (raid) {
                    setRaidStatus(raid);
                }
                if(swap){
                    setSwapStatus(swap);
                }
            })
            .catch(err => {
                console.log(err)
            })
    }, []);

    return (
        <div className='deviceHealthStatus'>
            <>
                {
                    (diskStatus.status === 'failed' && raidStatus.status === 'failed') &&
                    <DeviceStatusContainer>
                        <FailedStatus title={`• ${diskStatus.message} \n\n • ${raidStatus.message}`} data-tip data-for='tooltip'>{Constants.HEALTH_STATUS_TYPES['failed']}</FailedStatus>
                    </DeviceStatusContainer>
                }
                {
                    (diskStatus.status === 'recovering' && raidStatus.status === 'failed') &&
                    <DeviceStatusContainer>
                        <FailedStatus title={` ${raidStatus.message}`} data-tip data-for='tooltip'>{Constants.HEALTH_STATUS_TYPES['failed']}</FailedStatus>
                    </DeviceStatusContainer>
                }
                {
                    (diskStatus.status === 'failed' && raidStatus.status === 'warning') &&
                    <DeviceStatusContainer>
                        <FailedStatus title={`${diskStatus.message}`} data-tip data-for='tooltip'>{Constants.HEALTH_STATUS_TYPES['failed']}</FailedStatus>
                    </DeviceStatusContainer>
                }
                {
                    (diskStatus.status === 'recovering' && raidStatus.status === 'warning') &&
                    <DeviceStatusContainer>
                        <FailedStatus title={raidStatus.message} data-tip data-for='tooltip'>{Constants.HEALTH_STATUS_TYPES['warning']}</FailedStatus>
                    </DeviceStatusContainer>
                }
                {
                    (diskStatus.status === 'ok' && raidStatus.status === 'failed') &&
                    <DeviceStatusContainer>
                        <FailedStatus title={`${raidStatus.message}`} data-tip data-for='tooltip'>{Constants.HEALTH_STATUS_TYPES['failed']}</FailedStatus>
                    </DeviceStatusContainer>
                }
                {
                    (diskStatus.status === 'ok' && raidStatus.status === 'warning') &&
                    <DeviceStatusContainer>
                        <ReconciliationStatus title={`${raidStatus.message}`} data-tip data-for='tooltip'>{Constants.HEALTH_STATUS_TYPES['warning']}</ReconciliationStatus>
                    </DeviceStatusContainer>
                }
                {
                    (diskStatus.status === 'failed' && raidStatus.status === 'ok') &&
                    <DeviceStatusContainer>
                        <FailedStatus title={`${diskStatus.message}`} data-tip data-for='tooltip'>{Constants.HEALTH_STATUS_TYPES['failed']}</FailedStatus>
                    </DeviceStatusContainer>
                }
                {
                    (diskStatus.status === 'recovering' && raidStatus.status === 'ok') &&
                    <DeviceStatusContainer>
                        <ReconciliationStatus title={`${diskStatus.message}`} data-tip data-for='tooltip'>{Constants.HEALTH_STATUS_TYPES['recovering']}</ReconciliationStatus>
                    </DeviceStatusContainer>
                }
                {
                    (diskStatus.status === 'failed' && swapStatus.status === 'warning' && raidStatus === '') &&
                    <DeviceStatusContainer>
                        <FailedStatus title={diskStatus.message} data-tip data-for='tooltip'>{Constants.HEALTH_STATUS_TYPES['failed']}</FailedStatus>
                    </DeviceStatusContainer>
                }
                {
                    (diskStatus.status === 'recovering' && raidStatus === '' && swapStatus.status === 'ok') &&
                    <DeviceStatusContainer>
                        <ReconciliationStatus title={diskStatus.message} data-tip data-for='tooltip'>{Constants.HEALTH_STATUS_TYPES['recovering']}</ReconciliationStatus>
                    </DeviceStatusContainer>
                }
                {
                    (diskStatus.status === 'recovering' && swapStatus.status === 'warning') &&
                    <DeviceStatusContainer>
                        <ReconciliationStatus title={swapStatus.message} data-tip data-for='tooltip'>{Constants.HEALTH_STATUS_TYPES['warning']}</ReconciliationStatus>
                    </DeviceStatusContainer>
                }
                {
                    (diskStatus.status === 'ok' && swapStatus.status === 'warning') &&
                    <DeviceStatusContainer>
                        <ReconciliationStatus title={swapStatus.message} data-tip data-for='tooltip'>{Constants.HEALTH_STATUS_TYPES['warning']}</ReconciliationStatus>
                    </DeviceStatusContainer>
                }
                {
                    (diskStatus.status === 'failed' && swapStatus.status === 'ok') &&
                    <DeviceStatusContainer>
                        <FailedStatus title={`${diskStatus.message}`} data-tip data-for='tooltip'>{Constants.HEALTH_STATUS_TYPES['failed']}</FailedStatus>
                    </DeviceStatusContainer>
                }
                {
                    (raidStatus.status === 'failed' && swapStatus.status === 'warning') &&
                    <DeviceStatusContainer>
                        <FailedStatus title={raidStatus.message} data-tip data-for='tooltip'>{Constants.HEALTH_STATUS_TYPES['failed']}</FailedStatus>
                    </DeviceStatusContainer>
                }
                {
                    ((raidStatus.status === 'ok' || raidStatus.status === '') && swapStatus.status === 'warning') &&
                    <DeviceStatusContainer>
                        <ReconciliationStatus title={swapStatus.message} data-tip data-for='tooltip'>{Constants.HEALTH_STATUS_TYPES['warning']}</ReconciliationStatus>
                    </DeviceStatusContainer>
                }
                {
                    (raidStatus.status === 'warning' && swapStatus.status === 'warning') &&
                    <DeviceStatusContainer>
                        <ReconciliationStatus title={`• ${raidStatus.message} \n\n • ${swapStatus.message}`} data-tip data-for='tooltip'>{Constants.HEALTH_STATUS_TYPES['warning']}</ReconciliationStatus>
                    </DeviceStatusContainer>
                }
            </>
        </div>
    )
}

export default DeviceHealthStatus